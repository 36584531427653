import React, { useState } from "react";
import { useEffect } from "react";
import { ReactSession } from "react-client-session";
import axios from "axios";
import "./reason_list.css";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ReplayIcon from "@material-ui/icons/Replay";

function ReasonList() {
  const [reasons, setReasons] = useState([]);
  useEffect(() => {
    fetchAllReasons();

    return () => {};
  }, []);

  const fetchAllReasons = () => {
    var access_token = "Bearer " + ReactSession.get("access_token");
    axios
      .get(global.config.apiGateway.URL + "/admin/reasons/all", {
        headers: { Authorization: access_token },
      })
      .then((response) => {
        console.log(response.data);
        setReasons(response.data);
      })
      .catch((error) => {
        if (error.response) {
          console.error(error.response.data);
        }
      });
  };

  const addReason = () => {
    let typeSelect = document.getElementById("reason_type");
    let valueInput = document.getElementById("reason_value");
    let dependencyTypeSelect = document.getElementById(
      "reason_dependency_type"
    );
    if (!typeSelect || !valueInput || !dependencyTypeSelect) {
      alert("No input fields on dom");
      return;
    }
    if (
      typeSelect.value == -1 ||
      valueInput.value === "" ||
      dependencyTypeSelect.value == -1
    ) {
      alert("Please provide all input values");
      return;
    }
    let access_token = "Bearer " + ReactSession.get("access_token");
    let formdata = new FormData();
    formdata.append("type", typeSelect.value);
    formdata.append("value", valueInput.value);
    formdata.append("dependency_type", dependencyTypeSelect.value);
    axios({
      method: "POST",
      url: global.config.apiGateway.URL + "/admin/reasons/create",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: access_token,
      },
    })
      .then((response) => {
        console.log("added reason " + response.data);
        fetchAllReasons();
        document.getElementById("reason_type").value = "-1";
        document.getElementById("reason_value").value = "";
        document.getElementById("reason_dependency_type").value = "-1";
      })
      .catch((error) => {
        if (error.response) {
          console.error(String(error));
        }
      });
  };

  const setActiveStatus = (reasonId, status) => {
    if (!reasonId) return;

    console.log(reasonId);
    let access_token = "Bearer " + ReactSession.get("access_token");
    axios({
      method: "PUT",
      url:
        global.config.apiGateway.URL +
        "/admin/reasons/" +
        reasonId +
        "/" +
        status,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: access_token,
      },
    })
      .then((response) => {
        console.log(response);
        fetchAllReasons();
      })
      .catch((error) => {
        if (error) {
          console.error(String(error));
        }
      });
  };

  return (
    <div>
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Reason ID</th>
            <th scope="col">Type</th>
            <th scope="col">Value</th>
            <th scope="col">Dependency FormField Value</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {reasons.map((item) => (
            <tr>
              <td>{item.id}</td>
              <td>{item.type}</td>
              <td>{item.value}</td>
              <td>{item.dependency_type}</td>
              <td>{item.is_active ? "Active" : "Deleted"}</td>
              <td>
                {item.is_active ? (
                  <DeleteForeverIcon
                    name="deleteReason"
                    title="Disable this reason."
                    style={{ fontSize: "25px", cursor: "pointer" }}
                    onClick={() => setActiveStatus(item.id, "delete")}
                  />
                ) : (
                  <ReplayIcon
                    name="enableReason"
                    title="Reactivate this deleted reason."
                    style={{ fontSize: "25px", cursor: "pointer" }}
                    onClick={() => setActiveStatus(item.id, "reactivate")}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className="center_children"
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
        <button
          className="btn btn-primary"
          onClick={() =>
            document.getElementById("add_reason").classList.toggle("visibility")
          }
        >
          Add Reason
        </button>
      </div>
      <div className="center_children">
        <div id="add_reason" className="visibility">
          <select
            className="reason_input_element"
            name="type"
            id="reason_type"
            title=""
          >
            <option value="-1" selected disabled>
              Select Type of Reason
            </option>
            <option value="approve">Approve</option>
            <option value="reject">Reject</option>
          </select>
          <input
            type="text"
            name="reason_value"
            id="reason_value"
            placeholder="Provide reason text that will be displayed"
            maxLength={295}
            className="reason_input_element"
          />
          <select
            name="reason_dependency_type"
            className="reason_input_element"
            id="reason_dependency_type"
            title="If dependency type is video, this reason will only show on task instance with video form field."
          >
            <option value="-1" selected disabled>
              Select Form Field Type
            </option>
            <option value="general">General</option>
            <option value="long_text">Long Text</option>
            <option value="radio">Radio</option>
            <option value="image">Image</option>
            <option value="integer">Integer</option>
            <option value="back_camera_video">Back Camera Video</option>
            <option value="short_text">Short Text</option>
            <option value="location">Location</option>
            <option value="video">Video</option>
            <option value="merchant_name">Merchant Name</option>
            <option value="back_camera_image">Back Camera Image</option>
            <option value="checkbox">Checkbox</option>
            <option value="kisan_phone">Kisan Phone</option>
            <option value="front_camera_image">Front Camera Image</option>
            <option value="kisan_name">Kisan Name</option>
            <option value="numeric">Numeric</option>
            <option value="amount">Amount</option>
            <option value="phone">Phone</option>
            <option value="date">Data</option>
            <option value="merchant_phone">Merchant Phone</option>
          </select>
          <div className="reason_controls">
            <button
              className="btn btn-primary"
              style={{
                width: "fit-content",
                alignSelf: "self-end",
                padding: "6px 20px",
              }}
              onClick={addReason}
              title="Submit the form and create a new reason."
            >
              Finish
            </button>
            <button
              className="btn btn-default"
              style={{
                width: "fit-content",
                alignSelf: "self-end",
                padding: "6px 20px",
              }}
              title="hide Add Reason form"
              onClick={() =>
                document
                  .getElementById("add_reason")
                  .classList.toggle("visibility")
              }
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReasonList;
