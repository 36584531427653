import React, { Component } from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import axios from 'axios';

import Navbar from './components/navbar';
import Login from './components/login/login';
import Logout from './components/login/logout';
import ViewJobs from './components/job/view_jobs';
import Job from './components/job/job';
import Task from './components/task/task';
import JobInstanceList from './components/job_instance/job_instance_list';
import JobInstance from './components/job_instance/job_instance';
import TaskInstanceList from './components/task_instance/task_instance_list';
import TaskInstance from './components/task_instance/task_instance';
import PartnerProfile from './components/partner/partner_profile';
import PartnerTransactions from './components/partner/partner_transactions';
import DownloadResponses from './components/download_responses/download_responses';
import './App.css';
import KycList from './components/kyc_list/kycList';
import PaymentSheet from './components/payment_sheet/payment_sheet';
import Leaderboard from './components/leaderboard/leaderboard';
import KYC_Details from "./components/kyc_details/kycDetails";
import ReasonList from "./components/reason/reason_list";
import Transactions from './components/transactions/transactions';

class App extends Component {
  constructor() {
    super();
    this.state = {
      states: false,
      districts: false,
      sub_divisions: false,
    };
    ReactSession.setStoreType("localStorage");
  }

  componentDidMount() {
    this.fetch_segments();
  }
  fetch_segments = () => {
    var access_token = "Bearer " + ReactSession.get("access_token");
    if (typeof ReactSession.get("states") === "undefined") {
      axios
        .get(global.config.apiGateway.URL + "/admin/geo/states", {
          headers: {
            Authorization: access_token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            ReactSession.set("states", response.data);
            this.setState({ states: true });
          }
        })
        .catch((err) => {
          console.error(String(err));
        });
    }
    if (typeof ReactSession.get("districts") === "undefined") {
      axios
        .get(global.config.apiGateway.URL + "/admin/geo/districts", {
          headers: {
            Authorization: access_token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            ReactSession.set("districts", response.data);
            this.setState({ districts: true });
          }
        })
        .catch((err) => {
          console.error(String(err));
        });
    }
    if (typeof ReactSession.get("sub_divisions") === "undefined") {
      axios
        .get(global.config.apiGateway.URL + "/admin/geo/subdivisions", {
          headers: {
            Authorization: access_token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            ReactSession.set("sub_divisions", response.data);
            this.setState({ sub_divisions: true });
          }
        })
        .catch((err) => {
          console.error(String(err));
        });
    }
    /*axios.get(global.config.apiGateway.URL+"/api/fetch_segment_village")
        .then((response) => {
          if(response.status === 200){
            ReactSession.set("villages", response.data);
          }
        })
        .catch((err)=>{
          console.error(String(err));
        });*/
  };
  render() {
    return (
      <BrowserRouter>
        <div className="App container">
          <Navbar />
          <div
            className="content"
            style={{ paddingTop: "70px", paddingBottom: "50px" }}
          >
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/view_jobs" component={ViewJobs} />
              <Route exact path="/job/:job_id" component={Job} />
              <Route exact path="/task/:task_id" component={Task} />
              <Route
                exact
                path="/job/:job_id/job_instances"
                component={JobInstanceList}
              />
              <Route
                exact
                path="/job/:job_id/task_instances"
                key="task_instances_by_job_id"
                component={TaskInstanceList}
              />
              <Route
                exact
                path="/task/:task_id/task_instances"
                key="task_instances_by_task_id"
                component={TaskInstanceList}
              />
              <Route
                exact
                path="/task_instances"
                key="task_instances_all"
                component={TaskInstanceList}
              />
              <Route
                exact
                path="/task_instance/:task_instance_id"
                component={TaskInstance}
              />
              <Route
                exact
                path="/job_instance/:job_instance_id"
                component={JobInstance}
              />
              <Route
                exact
                path="/job_instance/:job_instance_id/task_instances?page=1&status=all"
                key="task_instances_by_job_instance_id"
                component={TaskInstanceList}
              />
              <Route
                exact
                path="/job_instances"
                key="job_instances_all"
                component={JobInstanceList}
              />
              <Route
                exact
                path="/partner_profile/:user_id"
                component={PartnerProfile}
              />
              <Route
                exact
                path="/partner_profile/:user_id/transactions"
                component={PartnerTransactions}
              />
              <Route exact path="/kyc_list" component={KycList} />
              <Route exact path="/payment_sheet" component={PaymentSheet} />
              <Route
                exact
                path="/download_responses"
                component={DownloadResponses}
              />
              <Route exact path="/leaderboard" component={Leaderboard} />
              <Route
                exact
                path="/kyc_card/:user_id/:kyc_check"
                component={KYC_Details}
              />
              <Route exact path="/reasons" component={ReasonList} />
              <Route 
              exact 
              path="/transactions" 
              component={Transactions}
              />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
