import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ReactSession } from "react-client-session";
import axios from "axios";
import EditableLabel from "react-inline-editing";
import TaskForm from "../task/task_form";
import TaskList from "../task/task_list";
import JobSegmentList from "./job_segment_list";
import JobSegForm from "./job_segment_form";

class Job extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job_id: props.match.params.job_id,
      found: false,
      logged_in: false,
      show_form: false,
      show_user_constraint_form: false,
      partner_role_mapping: [],
    };

    if (
      typeof ReactSession.get("username") !== "undefined" &&
      typeof ReactSession.get("access_token") !== "undefined"
    ) {
      this.state.logged_in = true;
      this.state.username = ReactSession.get("username");
    }
    this.fetch_job = this.fetch_job.bind(this);
    this.generate_job_data = this.generate_job_data.bind(this);
    this.hide_or_show_task_form = this.hide_or_show_task_form.bind(this);
    this.hide_or_show_segment_form = this.hide_or_show_segment_form.bind(this);
    this.approve_job = this.approve_job.bind(this);
    this.delete_job = this.delete_job.bind(this);
    this.delete_job_segment = this.delete_job_segment.bind(this);
    this.count_task_stage = this.count_task_stage.bind(this);
  }

  componentDidMount() {
    if (this.state.logged_in) this.fetch_job();
  }

  fetch_job = () => {
    var access_token = "Bearer " + ReactSession.get("access_token");

    axios
      .get(global.config.apiGateway.URL + "/admin/jobs/" + this.state.job_id, {
        headers: {
          Authorization: access_token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("Fetched Job");
          this.setState({
            job_data: response.data,
            found: true,
          });
        }
      })
      .catch((error) => {
        console.error(String(error));
      });
    if (this.state.partner_role_mapping.length == 0) {
      axios
        .get(global.config.apiGateway.URL + "/admin/partner-role-mapping", {
          headers: {
            Authorization: access_token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("Fetched mapping");
            this.setState({
              partner_role_mapping: response.data,
            });
          }
        })
        .catch((error) => {
          console.error(String(error));
        });
    }
  };

  handleFocusName(text) {
    console.log("Focused with text: " + text);
  }

  handleFocusOutName(text) {
    console.log("Left editor with text: " + text);
  }

  generate_job_data = () => {
    var job_data;
    job_data = (
      <div className="col-md-10 row">
        <div className="col-md-12 row">
          <div className="col-md-4">
            <h3>Job Title :</h3>
          </div>
          <div className="col-md-8">
            {/* <h3>{this.state.job_data.title}</h3> */}
            <h3>
              <EditableLabel
                text={this.state.job_data.title}
                labelClassName="myLabelClass"
                inputClassName="myInputClass"
                inputWidth="200px"
                inputHeight="35px"
                inputMaxLength="50"
                labelFontWeight="bold"
                inputFontWeight="bold"
                onFocus={this.handleFocusName}
                onFocusOut={this.handleFocusOutName}
              />
            </h3>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-4">
            <h4>Job ID :</h4>
          </div>
          <div className="col-md-8">
            <h4>{this.state.job_data.id}</h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-4">
            <h4>Job Description :</h4>
          </div>
          <div className="col-md-8">
            {/* <h4>{this.state.job_data.description}</h4> */}
            <h4>
              <EditableLabel
                text={this.state.job_data.description}
                labelClassName="myLabelClass"
                inputClassName="myInputClass"
                inputWidth="270px"
                inputHeight="35px"
                // inputMaxLength="50"
                labelFontWeight="bold"
                inputFontWeight="bold"
                onFocus={this.handleFocusName}
                onFocusOut={this.handleFocusOutName}
              />
            </h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-4">
            <h4>Job Instruction :</h4>
          </div>
          <div className="col-md-8">
            <EditableLabel
              text={
                this.state.job_data.instruction_file_url !== null ? (
                  <h4>
                    <a
                      href={this.state.job_data.instruction_file_url}
                      rel="noreferrer"
                      target="_blank"
                    >
                      View Instruction File
                    </a>
                  </h4>
                ) : (
                  <h4 style={{ color: "red" }}>No Instruction File</h4>
                )
              }
              labelClassName="myLabelClass"
              inputClassName="myInputClass"
              inputWidth="270px"
              inputHeight="35px"
              inputMaxLength="50"
              labelFontWeight="bold"
              inputFontWeight="bold"
              onFocus={this.handleFocusName}
              onFocusOut={this.handleFocusOutName}
            />
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-4">
            <h4>Created By :</h4>
          </div>
          <div className="col-md-8">
            <h4>{this.state.job_data.created_by_name}</h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-4">
            <h4>Approved By :</h4>
          </div>
          <div className="col-md-8">
            <h4>
              {this.state.job_data.approved_by ? (
                this.state.job_data.approved_by_name
              ) : (
                <p style={{ color: "red" }}>Not approved</p>
              )}
            </h4>
          </div>
        </div>
        {this.state.job_data.deleted_by && (
          <div className="col-md-12 row">
            <div className="col-md-4">
              <h4>Deleted By :</h4>
            </div>
            <div className="col-md-8">
              <h4>{this.state.job_data.deleted_by_name}</h4>
            </div>
          </div>
        )}
        <div className="col-md-12 row">
          <div className="col-md-4">
            <h4>Active or Not :</h4>
          </div>
          <div className="col-md-8">
            {this.state.job_data.is_active ? (
              <h5 style={{ color: "green" }}>Active✔️</h5>
            ) : (
              <h5 style={{ color: "red" }}>Not Active</h5>
            )}
          </div>
        </div>
      </div>
    );
    return job_data;
  };

  hide_or_show_form = () => {
    this.setState({ show_form: !this.state.show_form });
  };

  approve_job = () => {
    if (!window.confirm("Approve this Job?")) return;
    var access_token = "Bearer " + ReactSession.get("access_token");
    var formdata = new FormData();
    formdata.append("job_id", this.state.job_id);
    axios({
      method: "put",
      url:
        global.config.apiGateway.URL +
        "/admin/jobs/" +
        this.state.job_id +
        "/approval",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: access_token,
      },
    })
      .then((response) => {
        console.log(String(response));
        if (response.status === 200) {
          alert("Job Approved");
          this.fetch_job();
        }
      })
      .catch((err) => {
        console.log(String(err));
        if (err.response.status === 400) {
          alert(err.response.data);
        }
        if (err.response.status === 404) {
          alert("Job not found");
        }
      });
  };

  delete_job = () => {
    if (!window.confirm("Delete this Job?")) return;
    var access_token = "Bearer " + ReactSession.get("access_token");
    var formdata = new FormData();
    formdata.append("job_id", this.state.job_id);
    axios({
      method: "delete",
      url: global.config.apiGateway.URL + "/admin/jobs/" + this.state.job_id,
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: access_token,
      },
    })
      .then((response) => {
        console.log(String(response));
        if (response.status === 200) {
          alert("Job Deleted");
          this.fetch_job();
        }
      })
      .catch((err) => {
        console.log(String(err));
        if (err.response.status === 400) {
          alert(err.response.data);
        }
        if (err.response.status === 404) {
          alert("Job not found");
        }
      });
  };

  delete_job_segment = (e) => {
    if (!window.confirm("Delete this Job Segment?")) return;
    var access_token = "Bearer " + ReactSession.get("access_token");
    var formdata = new FormData();
    formdata.append("job_seg_map_id", e.target.id);
    axios({
      method: "delete",
      url: global.config.apiGateway.URL + "/admin/jobs/segments/" + e.target.id,
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: access_token,
      },
    })
      .then((response) => {
        console.log(String(response));
        if (response.status === 200) {
          alert("Job Segment Deleted");
          this.fetch_job();
        }
      })
      .catch((err) => {
        console.log(String(err));
        if (err.response.status === 400) {
          alert(err.response.data);
        }
        if (err.response.status === 404) {
          alert("Job not found");
        }
      });
  };

  count_task_stage = () => {
    var stage = 0;
    this.state.job_data.tasks.map((task) => {
      if (!task.deleted_by) {
        if (task.stage > stage) stage = task.stage;
      }
      return null;
    });
    return stage;
  };

  hide_or_show_task_form = () => {
    this.setState({ show_task_form: !this.state.show_task_form });
  };

  hide_or_show_segment_form = () => {
    this.setState({ show_segment_form: !this.state.show_segment_form });
  };

  render() {
    if (!this.state.logged_in) {
      return (
        <div>
          <h5>Not logged in !</h5>
        </div>
      );
    } else if (!this.state.found) {
      return (
        <div>
          <h5>Job {this.state.job_id} not found !</h5>
        </div>
      );
    } else {
      var job_data = this.generate_job_data();
      return (
        <div>
          <div className="page-header text-center">
            <h1>Job - {this.state.job_data.title}</h1>
          </div>
          <div className="row">
            <div className="col-md-12 row">
              {job_data}
              <div className="col-md-2 row">
                {/* <div className="col-md-12" style={{ margin: "5px" }}>
                  <Link to={"/job/" + this.state.job_id + "/job_instances"}>
                    View Job Instances
                  </Link>
                </div> */}
                <div className="col-md-12" style={{ margin: "5px" }}>
                  <Link
                    to={
                      "/job/" +
                      this.state.job_id +
                      "/task_instances?page=1&status=all"
                    }
                  >
                    View Task Instances
                  </Link>
                </div>
                <div className="col-md-12" style={{ margin: "5px" }}>
                  {!this.state.job_data.deleted_by ? (
                    this.state.job_data.is_active ? (
                      <p style={{ color: "green" }}>Job Approved✔️</p>
                    ) : this.state.job_data.can_be_approved ? (
                      <button className="btn" onClick={this.approve_job}>
                        Approve Job
                      </button>
                    ) : (
                      <p style={{ color: "red" }}>
                        A Job needs atleast one active task to be Approved
                      </p>
                    )
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-12" style={{ margin: "5px" }}>
                  {!this.state.job_data.deleted_by ? (
                    <button
                      className="btn btn-danger"
                      onClick={this.delete_job}
                    >
                      Delete Job
                    </button>
                  ) : (
                    <p style={{ color: "red" }}>Job Deleted❌</p>
                  )}
                </div>
              </div>
            </div>

            <div
              className="col-md-12 row page-header"
              style={{ "border-top": "1px solid #eee" }}
            >
              <div className="col-md-8">
                <h4>Manage Constraints</h4>
              </div>
              {!this.state.job_data.deleted_by && (
                <div className="col-md-4">
                  <button
                    className="btn btn-sm"
                    onClick={this.hide_or_show_segment_form}
                    style={{ "margin-top": "10px" }}
                  >
                    Add Constraint
                  </button>
                </div>
              )}
            </div>
            {this.state.show_segment_form && (
              <JobSegForm
                job_id={this.state.job_id}
                reload={this.fetch_job}
                partner_role_mapping={this.state.partner_role_mapping}
              />
            )}

            <JobSegmentList
              segments={this.state.job_data.segments}
              delete_job_segment={this.delete_job_segment}
            />
            <div className="col-md-12 row page-header">
              <div className="col-md-8">
                <h3>Tasks</h3>
              </div>
              {!this.state.job_data.deleted_by && (
                <div className="col-md-4">
                  <button
                    className="btn"
                    onClick={this.hide_or_show_task_form}
                    style={{ "margin-top": "10px" }}
                  >
                    Create a Task
                  </button>
                </div>
              )}
            </div>
            {this.state.show_task_form && (
              <TaskForm
                job_id={this.state.job_id}
                hide={this.hide_or_show_task_form}
                stage={this.count_task_stage() + 1}
                reload={this.fetch_job}
              />
            )}
            <TaskList tasks={this.state.job_data.tasks} />
          </div>
        </div>
      );
    }
  }
}

export default Job;
