import axios from "axios";
import { useEffect, useState } from "react";
import { ReactSession } from "react-client-session";
import ReactPaginate from "react-paginate";

import RemittanceTable from "./RemittanceTable";
import TaskTable from "./TaskTable";
import AddTransaction from "./AddTransaction";

const Transactions = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [currentMonth, setCurrentMonth] = useState("");
  const [transactionMonth, setTransactionMonth] = useState("");
  const [transactionData, setTransactionData] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isAddTransActive, setIsAddTransActive] = useState(false);
  const [isPaginateActive, setIsPaginateActive] = useState(true);
  const [activeTransactionType, setActiveTransactionType] =
    useState("remittance");
  const [isLoading, setIsLoading] = useState(false);
  const [paginateData, setPaginateData] = useState({
    currentPage: 1,
    totalResults: 50,
    limit: 50,
  });

  //func to fetch transaction data
  const fetchData = (currentPage = 1, limit = paginateData.limit) => {
    //console.log("fetched");
    setIsLoading(true);
    const access_token = "Bearer " + ReactSession.get("access_token");
    const year = transactionMonth.slice(0, 4);
    const month = parseInt(transactionMonth.slice(5));
    const page = currentPage;
    const subUrl = {
      remittance: "/admin/remittances",
      task: "/admin/task-transactions",
    };
    axios
      .get(
        global.config.apiGateway.URL +
          `${subUrl[activeTransactionType]}?month=${month}&year=${year}&page=${page}&limit=${limit}`,
        {
          headers: { Authorization: access_token },
        }
      )
      .then((response) => {
        setIsLoading(false);
        setIsPaginateActive(true);
        if (response.status === 200) {
          setTransactionData(response.data.data);
          setPaginateData({
            ...paginateData,
            totalResults: response.data.total_results,
            currentPage: page,
            limit: limit,
          });
          setIsDataFetched(true);
        } else alert("status:" + response.status);
      })
      .catch((error) => {
        console.error(String(error));
        setIsDataFetched(false);
        setIsLoading(false);
        try {
          alert(error.response.data);
        } catch {
          alert(String(error));
        }
      });
  };

  //useEffects
  useEffect(() => {
    //checking for login status
    if (
      typeof ReactSession.get("username") !== "undefined" &&
      typeof ReactSession.get("access_token") !== "undefined"
    ) {
      setLoggedIn(true);
      //setting transaction month as current month
      const currentMonth =
        new Date().getMonth() < 9
          ? `0${new Date().getMonth() + 1}`
          : new Date().getMonth() + 1;
      setCurrentMonth(`${new Date().getFullYear()}-${currentMonth}`);
      setTransactionMonth(`${new Date().getFullYear()}-${currentMonth}`);
    }
  }, []);

  //event handlers
  const handleFetchClick = () => {
    setIsPaginateActive(false);
    fetchData(1);
  };
  const handlePaginateClick = (data) => fetchData(data.selected + 1);

  const handleTransactionTypeClick = (e) => {
    let children = e.currentTarget.children;
    children = Array.from(children);
    const target = e.target;
    for (const child of children) {
      if (child === target) {
        child.classList.add("btn-primary");
        if (activeTransactionType !== target.name) setIsDataFetched(false);
        setActiveTransactionType(target.name);
      } else child.classList.remove("btn-primary");
    }
  };

  // JSX
  if (!isLoggedIn) return <h5>Not logged in !</h5>;
  return (
    <div>
      <div>
        {/* header */}
        <div>
          <h2
            style={{
              display: "inline-block",
              marginTop: "30px",
              marginBottom: "20px",
            }}
          >
            Transactions
          </h2>
          <div
            className="btn-group pull-right"
            role="group"
            aria-label="..."
            style={{ marginTop: "30px" }}
            onClick={(e) => handleTransactionTypeClick(e)}
          >
            <button
              type="button"
              className="btn btn-default btn-primary"
              name="remittance"
            >
              Remittance
            </button>
            <button type="button" className="btn btn-default" name="task">
              Task
            </button>
          </div>
        </div>
        {/* ----> header */}
        <div>
          <label htmlFor="transaction-month">Select Month</label>
          <div style={{ marginBottom: "10px" }}>
            <input
              style={{ marginRight: "10px" }}
              type="month"
              name="transaction-month"
              id="transaction-month"
              value={transactionMonth}
              min="2000-01"
              max={currentMonth}
              onChange={(e) => setTransactionMonth(e.target.value)}
            />
            <button className="btn btn-default" onClick={handleFetchClick}>
              {isLoading ? "Fetching..." : "Fetch Data"}
            </button>
          </div>
          <button
            type="button"
            className="btn btn-default"
            onClick={() => setIsAddTransActive(!isAddTransActive)}
          >
            Add Transaction
          </button>
        </div>
      </div>
      <hr />
      {isAddTransActive && <AddTransaction />}
      {!isDataFetched ? (
        <h5>No transaction to show</h5>
      ) : (
        <>
          {activeTransactionType === "remittance" && (
            <RemittanceTable
              data={transactionData}
              transactionMonth={transactionMonth}
              paginateData={paginateData}
              setPaginateData={setPaginateData}
              fetchData={fetchData}
              transactionType={activeTransactionType}
            />
          )}
          {activeTransactionType === "task" && (
            <TaskTable
              data={transactionData}
              transactionMonth={transactionMonth}
              paginateData={paginateData}
              setPaginateData={setPaginateData}
              fetchData={fetchData}
              transactionType={activeTransactionType}
            />
          )}
          {isPaginateActive && (
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              pageCount={Math.ceil(
                paginateData.totalResults / paginateData.limit
              )}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={(e) => handlePaginateClick(e)}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Transactions;
