import axios from "axios";
import { useEffect, useState } from "react";
import { ReactSession } from "react-client-session";

const AddTransaction = () => {
  const [details, setDetails] = useState({
    user_id: "",
    phone_no: "",
    amount: "",
    type: "-1",
    sub_type_id: "-1",
  });
  const [taskList, setTaskList] = useState();
  const [schemeList, setSchemeList] = useState();
  const [isTaskSchemeListFetched, setIsTaskSchemeListFetched] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const validateData = (details) => {
    if (details.user_id.length === 0 && details.phone_no.length === 0) {
      setErrorMessage("Provide either user id or phone number");
      return false;
    } else if (
      details.user_id.length !== 0 &&
      !/^[0-9]*$/.test(details.user_id)
    ) {
      setErrorMessage("Invalid user ID");
      return false;
    } else if (
      details.phone_no.length !== 0 &&
      !/^[0-9]{10}$/.test(details.phone_no)
    ) {
      setErrorMessage("Invalid phone number");
      return false;
    } else if (!/^[0-9.]+$/.test(details.amount)) {
      setErrorMessage("Invalid amount");
      return false;
    } else if (details.type === "-1") {
      setErrorMessage("Select type");
      return false;
    } else if (details.type === "1" && details.sub_type_id === "-1") {
      setErrorMessage("Select scheme");
      return false;
    } else if (details.type === "2" && details.sub_type_id === "-1") {
      setErrorMessage("Select task");
      return false;
    } else {
      setErrorMessage("");
      return true;
    }
  };

  // useEffects
  useEffect(() => {
    const access_token = "Bearer " + ReactSession.get("access_token");
    const fetchTaskSchemeList = () => {
      axios
        .get(global.config.apiGateway.URL + "/admin/tasks-schemes", {
          headers: { Authorization: access_token },
        })
        .then((response) => {
          if (response.status === 200) {
            response.data.schemes.sort((a, b) => {
              try {
                return a.title.localeCompare(b.title);
              } catch {}
              return 0;
            });
            response.data.tasks.sort((a, b) => {
              try {
                return a.title.localeCompare(b.title);
              } catch {}
              return 0;
            });
            setSchemeList(response.data.schemes);
            setTaskList(response.data.tasks);
            setIsTaskSchemeListFetched(true);
          } else alert("status:" + response.status);
        })
        .catch((error) => {
          console.error(String(error));
          try {
            alert(error.response.data);
          } catch {
            alert(String(error));
          }
        });
    };
    fetchTaskSchemeList();
  }, []);

  // event handlers
  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "type")
      setDetails({ ...details, type: value, sub_type_id: "-1" });
    else setDetails({ ...details, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateData(details)) {
      setIsLoading(true);
      const access_token = "Bearer " + ReactSession.get("access_token");
      const myForm = document.getElementById("add-transaction");
      const formData = new FormData(myForm);

      axios({
        method: "post",
        url: global.config.apiGateway.URL + "/admin/transactions/transaction",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: access_token,
        },
      })
        .then((response) => {
          setIsLoading(false);
          if (response.status === 200 || response.status === 201) {
            alert(response.data.message);
            setDetails({
              user_id: "",
              phone_no: "",
              amount: "",
              type: "-1",
              sub_type_id: "-1",
            });
          } else {
            alert(
              `status:${response.status}\n message:${response.data.message}`
            );
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(String(error));
          try {
            alert(error.response.data);
          } catch {
            alert(String(error));
          }
        });
    }
  };

  return (
    <div className="container">
      <h4>Add Transaction</h4>
      <form
        style={{ position: "relative" }}
        className="form-horizontal row"
        id="add-transaction"
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className="form-group">
          <label htmlFor="user_id" className="col-sm-2 control-label">
            User ID
          </label>
          <div className="col-sm-10">
            <input
              name="user_id"
              type="number"
              className="form-control"
              id="user_id"
              placeholder="User ID"
              value={details.user_id}
              onChange={(e) => handleOnChange(e)}
            />
          </div>
        </div>
        <h5 className="text-center">-- OR --</h5>
        <div className="form-group">
          <label htmlFor="phone_no" className="col-sm-2 control-label">
            Phone number
          </label>
          <div className="col-sm-10">
            <input
              name="phone_no"
              type="number"
              className="form-control"
              id="phone_no"
              placeholder="Phone number"
              value={details.phone_no}
              onChange={(e) => handleOnChange(e)}
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="amount" className="col-sm-2 control-label">
            Amount
          </label>
          <div className="col-sm-10">
            <input
              name="amount"
              type="number"
              className="form-control"
              id="amount"
              placeholder="Amount"
              value={details.amount}
              onChange={(e) => handleOnChange(e)}
            />
          </div>
        </div>
        {/* type dropdown menu */}
        <div className="form-group">
          <label htmlFor="type" className="col-sm-2 control-label">
            Type
          </label>
          <div className="col-sm-10">
            <select
              name="type"
              id="type"
              required
              onChange={(e) => handleOnChange(e)}
              value={details.type}
            >
              <option disabled value="-1">
                -- Select --
              </option>
              <option value="1">Scheme</option>
              <option value="2">Task</option>
              <option value="5">Remittance</option>
            </select>
          </div>
        </div>
        {/* scheme dropdown menu */}
        {isTaskSchemeListFetched && details.type === "1" && (
          <div className="form-group">
            <label htmlFor="sub_type_id" className="col-sm-2 control-label">
              Scheme
            </label>
            <div className="col-sm-10">
              <select
                name="sub_type_id"
                id="sub_type_id"
                required
                onChange={(e) => handleOnChange(e)}
                value={details.sub_type_id}
              >
                <option disabled value="-1">
                  -- Select --
                </option>
                {schemeList.map((scheme) => (
                  <option key={scheme.id} value={scheme.id}>
                    {scheme.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        {/* scheme dropdown menu */}
        {isTaskSchemeListFetched && details.type === "2" && (
          <div className="form-group">
            <label htmlFor="sub_type_id" className="col-sm-2 control-label">
              Task
            </label>
            <div className="col-sm-10">
              <select
                name="sub_type_id"
                id="sub_type_id"
                required
                onChange={(e) => handleOnChange(e)}
                value={details.sub_type_id}
              >
                <option disabled value="-1">
                  -- Select --
                </option>
                {taskList.map((task) => (
                  <option key={task.id} value={task.id}>
                    {task.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        {errorMessage && (
          <h6 className="text-danger text-center">Error: {errorMessage}</h6>
        )}
        <button
          className="btn btn-default"
          type="submit"
          style={{ float: "right" }}
        >
          {isLoading ? "Adding..." : "Add"}
        </button>
      </form>
      <hr />
    </div>
  );
};

export default AddTransaction;
