import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ReactSession } from "react-client-session";
import axios from "axios";
import FormField from "./form_field";
import moment from "moment";
import ReactAudioPlayer from "react-audio-player";
import "./task_instance.css";

class TaskInstance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      task_instance_id: props.match.params.task_instance_id,
      found: false,
      logged_in: false,
      task_data: null,
      form_fields: null,
      modal_status: "",
      reasons: [],
      subdivision: "",
      prevStage: null,
      nextStage: null
    };

    if (
      typeof ReactSession.get("username") !== "undefined" &&
      typeof ReactSession.get("access_token") !== "undefined"
    ) {
      this.state.logged_in = true;
      this.state.username = ReactSession.get("username");
    }
    this.fetch_task = this.fetch_task.bind(this);
    this.generate_task_data = this.generate_task_data.bind(this);
    this.approve_task = this.approve_task.bind(this);
    this.reject_task = this.reject_task.bind(this);
    this.showModal = this.showModal.bind(this);
    this.fetchReasons = this.fetchReasons.bind(this)
  }
  componentDidMount() {
    if (this.state.logged_in){
      this.fetch_task();
      this.fetchOtherStages();
    } 
  }

  componentDidUpdate(){
    if(this.state.logged_in && this.state.task_instance_id != this.props.match.params.task_instance_id){
      this.setState({
        task_instance_id: this.props.match.params.task_instance_id
      })
      this.fetch_task();
      this.fetchOtherStages()
    }
  }

  state = {
    toggle: true,
  };

  fetch_task = () => {
    var access_token = "Bearer " + ReactSession.get("access_token");
    var data = [];
    axios
      .get(
        global.config.apiGateway.URL +
          "/admin/tasks/instances/" +
          this.state.task_instance_id,
        {
          headers: { Authorization: access_token },
        }
      )
      .then((response) => {
        data = response.data;
        console.log(data.audio_url);
        this.setState({
          task_data: data,
          form_fields: data.form_fields,
          found: true,
          subdivision: response.data.subdivision
        });
      })
      .catch((error) => {
        console.error(String(error));
      });
  };

  fetchOtherStages=()=>{
    var access_token = "Bearer " + ReactSession.get("access_token");
    axios
      .get(
        global.config.apiGateway.URL +
          "/admin/tasks/instances/" +
          this.state.task_instance_id + "/other-stages",
        {
          headers: { Authorization: access_token },
        }
      )
      .then((response) => {
        console.log(response.data);
        if(response.data.prev_task_instance_id != -1){
          this.setState({
            prevStage: response.data.prev_task_instance_id
          });
        }
        if(response.data.next_task_instance_id != -1){
          this.setState({
            nextStage: response.data.next_task_instance_id
          })
        }
      })
      .catch((error) => {
        if(error.response){
          console.error(error.response.data)
        }else{
          console.error(String(error));
        }
      });
  }

  fetchReasons=()=> {
    // fetches and stores valid reasons
    console.log("fetching")
    var access_token = "Bearer " + ReactSession.get("access_token");
    axios
      .get(
        global.config.apiGateway.URL +
          "/admin/tasks/instances/" +
          this.state.task_instance_id +
          "/valid-reasons",
        {
          headers: { Authorization: access_token },
        }
      )
      .then((response) => {
        console.log(response.data);
        if(response.status===200){
          this.setState({reasons: response.data})
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error(error.response.data);
        }
      });
  }

  generate_form_field_data = () => {
    var form_data;
    form_data = this.state.form_fields.map((form_field) => {
      return <FormField form_data={form_field} key={form_field.id} />;
    });
    return form_data;
  };

  showModal() {
    if(this.state.reasons.length==0) this.fetchReasons();
    let modal_status = this.state.modal_status;
    if (modal_status === "") return "";
    var modal = (
      <div
        className="modal modal_parent"
        id="reason_modal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="reason-modal-header">
              <h5 className="modal-title">
                Please select a reason for{" "}
                {modal_status === "approve" ? "Approving" : "Rejecting"} this
                task instance.
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ modal_status: "" })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="reason-modal modal-body">
              {/* <div class="form-group"> */}
              {/* <label for="reason">
                Please select a valid reason for{" "}
                {this.state.modal_status === "approve"
                  ? " Approval"
                  : " Rejection"}
                !
              </label> */}
              {/* <input
                  type="text"
                  class="form-control"
                  id="reason"
                  maxLength={245}
                  placeholder="Provide a genuine reason, It will be visible to user"
                  onChange={(e) => this.setState({reason: e.target.value})}
                  required
                /> */}
              <select
                name="reason-select"
                id="reason-select"
                onChange={(e) => this.setState({ reason_id: e.target.value })}
              >
                <option className="" value="-1" selected disabled>
                  Select a reason for{" "}
                  {this.state.modal_status === "approve"
                    ? " Approval"
                    : " Rejection"}
                </option>
                {this.state.reasons
                  .filter((item) => item.type === this.state.modal_status)
                  .map((item) => (
                    <option value={item.id} className="reason-select-option">
                      {item.value}
                    </option>
                  ))}
              </select>
              {/* </div> */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  modal_status === "approve"
                    ? this.approve_task()
                    : this.reject_task();
                  this.setState({ modal_status: "" });
                }}
              >
                {modal_status}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => this.setState({ modal_status: "" })}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    return modal;
  }

  generate_task_data = () => {
    var task_data;
    task_data = (
      <div className="col-md-10 row">
        <div className="col-md-12 row">
          <div className="col-md-6">
            <h4>Task Title :</h4>
          </div>
          <div className="col-md-6">
            <h4>{this.state.task_data.title}</h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-6">
            <h4>Task Instance ID :</h4>
          </div>
          <div className="col-md-6">
            <h4>{this.state.task_data.id}</h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-6">
            <h4>Job Instance ID :</h4>
          </div>
          <div className="col-md-6">
            <h4>
              <Link
                to={"/job_instance/" + this.state.task_data.job_instance_id}
              >
                {this.state.task_data.job_instance_id}
              </Link>
            </h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-6">
            <h4>Fixed Amount :</h4>
          </div>
          <div className="col-md-6">
            <h4>₹ {this.state.task_data.fixed_amount}</h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-6">
            <h4>GP User ID :</h4>
          </div>
          <div className="col-md-6">
            <h4>{this.state.task_data.user_id}</h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-6">
            <h4>GP Name :</h4>
          </div>
          <div className="col-md-6">
            <h4>
              {this.state.task_data.user_name}{" "}
              <small>
                <Link to={"/partner_profile/" + this.state.task_data.user_id}>
                  View Profile
                </Link>
              </small>
            </h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-6">
            <h4 title="subdivision">GP Address Block :</h4>
          </div>
          <div className="col-md-6">
            <h4>{this.state.subdivision || "Not Provided"}</h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-6">
            <h4>Approved :</h4>
          </div>
          <div className="col-md-6">
            <h4>
              {this.state.task_data.is_approved === true && (
                <p style={{ color: "green" }}>Approved✔️</p>
              )}
              {this.state.task_data.is_approved === false && (
                <p style={{ color: "red" }}>Rejected❌</p>
              )}
              {this.state.task_data.is_approved === null &&
                "Approval Pending⌛"}
            </h4>
          </div>
        </div>
        {this.state.task_data.approved_by && (
          <div className="col-md-12 row">
            <div className="col-md-6">
              <h4>Approved By User :</h4>
            </div>
            <div className="col-md-6">
              <h4>{this.state.task_data.approved_by}</h4>
            </div>
          </div>
        )}
        {this.state.task_data.approved_by && (
          <div className="col-md-12 row">
            <div className="col-md-6">
              <h4>
                {this.state.task_data.is_approved && "Approval "}{" "}
                {this.state.task_data.is_approved === false && "Rejection "}{" "}
                Remark :
              </h4>
            </div>
            <div className="col-md-6">
              <h4>{this.state.task_data.remark || "Not Provided"}</h4>
            </div>
          </div>
        )}
        <div className="col-md-12 row">
          <div className="col-md-6">
            <h4>Start Time :</h4>
          </div>
          <div className="col-md-6">
            <h5>
              {moment(this.state.task_data.start_time).local().format("LLL")}
            </h5>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-6">
            <h4>End Time :</h4>
          </div>
          <div className="col-md-6">
            <h5>
              {this.state.task_data.end_time
                ? moment(this.state.task_data.end_time).local().format("LLL")
                : "Not ended"}
            </h5>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-6">
            <h4>Transaction ID :</h4>
          </div>
          <div className="col-md-6">
            <h4>
              {this.state.task_data.transaction_id
                ? this.state.task_data.transaction_id
                : "No transaction"}
            </h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-6">
            <h4>Previous Stage :</h4>
          </div>
          <div className="col-md-6">
            <a
              href={
                this.state.prevStage
                  ? "/task_instance/" + this.state.prevStage
                  : "#"
              }
            >
              <h4>
                {this.state.prevStage
                  ? this.state.prevStage
                  : "No Previous Stage"}
              </h4>
            </a>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-6">
            <h4>Next Stage :</h4>
          </div>
          <div className="col-md-6">
            <a
              href={
                this.state.nextStage
                  ? "/task_instance/" + this.state.nextStage
                  : "#"
              }
            >
              <h4>
                {this.state.nextStage ? this.state.nextStage : "No Next Stage"}
              </h4>
            </a>
          </div>
        </div>
      </div>
    );
    return task_data;
  };

  approve_task = () => {
    // if (!window.confirm("Approve this Task?")) return;
    var access_token = "Bearer " + ReactSession.get("access_token");
    var formdata = new FormData();
    // if (this.state.reason.length < 10){
    //   alert("Please provide a valid reason, with atleast 10 characters");
    //   return
    // }
    let reason_select = document.getElementById("reason-select");
    if(!reason_select) return;
    formdata.append("task_instance_id", this.state.task_instance_id);
    formdata.append("approved", "true");
    // formdata.append("reason", this.state.reason);
    formdata.append("reason_id", reason_select.value)
    axios({
      method: "put",
      url:
        global.config.apiGateway.URL +
        "/admin/tasks/instances/" +
        this.state.task_instance_id +
        "/approval",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: access_token,
      },
    })
      .then((response) => {
        console.log(String(response));
        if (response.status === 200) {
          alert("Task Approved");
          this.fetch_task();
        }
      })
      .catch((err) => {
        console.log(String(err));
        if (err.response.status === 400) {
          alert(err.response.data);
        }
        if (err.response.status === 404) {
          alert("Task instance not found");
        }
      });
  };

  reject_task = () => {
    // if (!window.confirm("Reject this Task?")) return;
    var access_token = "Bearer " + ReactSession.get("access_token");
    var formdata = new FormData();
    let reason_select = document.getElementById("reason-select");
    if (!reason_select) return;
    formdata.append("task_instance_id", this.state.task_instance_id);
    formdata.append("approved", "false");
    formdata.append("reason_id", reason_select.value)
    axios({
      method: "put",
      url:
        global.config.apiGateway.URL +
        "/admin/tasks/instances/" +
        this.state.task_instance_id +
        "/approval",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: access_token,
      },
    })
      .then((response) => {
        console.log(String(response));
        if (response.status === 200) {
          alert("Task Rejected");
          this.fetch_task();
        }
      })
      .catch((err) => {
        console.log(String(err));
        if (err.response.status === 400) {
          alert(err.response.data);
        }
        if (err.response.status === 404) {
          alert("Task instance not found");
        }
      });
  };

  render() {
    if (!this.state.logged_in) {
      return (
        <div>
          <h5>Not logged in !</h5>
        </div>
      );
    } else if (!this.state.found) {
      return (
        <div>
          <h5>Task Instance {this.state.task_instance_id} not found !</h5>
        </div>
      );
    } else {
      var task_data = this.generate_task_data();
      var form_data = this.generate_form_field_data();
      var modal = this.showModal();
      return (
        <div className="row">
          <div className="col-md-12 page-header text-center">
            <h1>
              <small>
                <Link to={"/task/" + this.state.task_data.task_id}>
                  Task ID - {this.state.task_data.task_id}
                </Link>
              </small>{" "}
              Task Instance - {this.state.task_data.id}{" "}
            </h1>
          </div>
          <div>{this.state.modal_status !== "" && modal}</div>
          <div className="col-md-12 row">
            <div className="col-md-8 row">{task_data}</div>
            <div className="col-md-4 row">
              {this.state.task_data.is_approved === null && (
                <div className="row" style={{ display: "flex" }}>
                  <div className="col-md-6" style={{ margin: "5px" }}>
                    <button
                      className="btn"
                      onClick={() => this.setState({ modal_status: "approve" })}
                      disabled={!this.state.task_data.end_time ? "True" : ""}
                    >
                      Approve Task
                    </button>
                  </div>
                  <div className="col-md-6" style={{ margin: "5px" }}>
                    <button
                      className="btn btn-danger"
                      onClick={() => this.setState({ modal_status: "reject" })}
                    >
                      Reject Task
                    </button>
                  </div>
                </div>
              )}
              {this.state.task_data.is_approved === true && (
                <p style={{ color: "green" }}>Approved✔️</p>
              )}
              {this.state.task_data.is_approved === false && (
                <p style={{ color: "red" }}>Rejected❌</p>
              )}
              {!this.state.task_data.end_time && (
                <div className="col-12" style={{ margin: "5px" }}>
                  <p style={{ color: "red" }}>
                    Can't Approve an Incomplete Task Instance
                  </p>
                </div>
              )}

              {this.state.task_data.audio_url !== null && (
                <div>
                  <h2 style={{ marginTop: "120px" }}>Play Audio</h2>
                  <div
                    className="row"
                    style={{ display: "flex", marginTop: "20px" }}
                  >
                    <ReactAudioPlayer
                      src={this.state.task_data.audio_url}
                      controls
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12 row">
            <div className="col-md-12 page-header">
              <h3>Form Fields</h3>
            </div>
            {form_data}
          </div>
        </div>
      );
    }
  }
}

export default TaskInstance;
