import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { ReactSession } from "react-client-session";
import axios from "axios";
import moment from "moment";
import ReactPaginate from "react-paginate";
import QueryString from "qs";
import "./UserSpecificTasks.css";

class UserSpecificTasks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      job_id: props.match.params.job_id,
      task_id: props.match.params.task_id,
      job_instance_id: props.match.params.job_instance_id,
      user_id: props.user_id,
      task_instances: [],
      logged_in: false,
      username: "",
      show_pending:
        QueryString.parse(this.props.history.location.search.substring(1))
          .status === "pending",
      show_approved:
        QueryString.parse(this.props.history.location.search.substring(1))
          .status === "approved",
      searchTerm: "",
      sort: QueryString.parse(this.props.history.location.search.substring(1))
        .sort
        ? QueryString.parse(this.props.history.location.search.substring(1))
            .sort
        : "",
      pageCount: 0,
      total_instances: 0,
      total_pending: 0,
      total_approved: 0,
      changeState: QueryString.parse(
        this.props.history.location.search.substring(1)
      ).status
        ? QueryString.parse(this.props.history.location.search.substring(1))
            .status
        : "all",
      userName: "",
      limit: localStorage.getItem("limit") ? localStorage.getItem("limit") : 10,
      currentPage: QueryString.parse(
        this.props.history.location.search.substring(1)
      ).page
        ? QueryString.parse(this.props.history.location.search.substring(1))
            .page
        : 1,
      reload: false,
      startDate: "",
      endDate: ""
    };

    if (this.state.job_id) {
      this.params = {
        fetchBy: "job_id",
        id: this.state.job_id,
      };
    } else if (this.state.task_id) {
      this.params = {
        fetchBy: "task_id",
        id: this.state.task_id,
      };
    } else if (this.state.job_instance_id) {
      this.params = {
        fetchBy: "job_instance_id",
        id: this.state.job_instance_id,
      };
    } else if (this.state.user_id) {
      this.params = {
        fetchBy: "user_id",
        id: this.state.user_id,
      };
    } else {
      this.params = {
        fetchBy: "all",
      };
    }

    if (typeof ReactSession.get("username") !== "undefined") {
      this.state.logged_in = true;
      this.state.username = ReactSession.get("username");
    }
    this.handleChange = this.handleChange.bind(this);
    this.fetch_task_instances = this.fetch_task_instances.bind(this);
    this.generate_task_instance_list =
      this.generate_task_instance_list.bind(this);
    this.hide_or_show_pending = this.hide_or_show_pending.bind(this);
    this.hide_or_show_approved = this.hide_or_show_approved.bind(this);
    this.hide_or_show_all = this.hide_or_show_all.bind(this);
  }

  handleChange = (e) => {
    let nam = e.target.name;
    let val = e.target.value;
    console.log("nam", nam);
    console.log(val);

    this.setState({
      userName: val,
    });
  };
  handleLimitChange = (e) => {
    let val = e.target.value;
    this.setState({ limit: val });
  };
  updateLimit = () => {
    const dataFormServer = this.fetchData(1);
    localStorage.setItem("limit", this.state.limit);
    this.setState({ task_instances: dataFormServer.data });
  };
  componentDidMount() {
    const qs = QueryString.parse(
      this.props.history.location.search.substring(1)
    );
    if (qs.status) {
      if (qs.status === "pending") {
        this.setState({
          show_pending: true,
          show_approved: false,
          changeState: "pending",
        });
      } else if (qs.status === "approved") {
        this.setState({
          show_approved: true,
          show_pending: false,
          changeState: "approved",
        });
      } else {
        this.setState({
          show_approved: false,
          show_pending: false,
          changeState: "all",
        });
      }
    }
    const access_token = "Bearer " + ReactSession.get("access_token");
    axios
      .get(
        global.config.apiGateway.URL +
          "/admin/tasks/instances?limit=" +
          this.state.limit,
        {
          headers: { Authorization: access_token },
          params: this.params,
        }
      )
      .then((response) => {
        this.setState({
          total_instances: response.data.total_results,
          total_pending: response.data.total_pending,
          total_approved: response.data.total_approved,
        });
      })
      .catch((error) => {
        console.error(String(error));
      });

    if (this.state.logged_in) {
      if (qs.status) {
        this.setState({
          currentPage: qs.page,
        });
        if (qs.status === "approved") {
          this.setState({
            show_approved: true,
            show_pending: false,
            changeState: "approved",
          });
          if (qs.sort) {
            this.props.history.push(
              "?page=" + qs.page + "&status=approved&sort=" + qs.sort
            );
          } else {
            this.props.history.push("?page=" + qs.page + "&status=approved");
          }
          this.setState({
            currentPage: qs.page,
          });
        } else if (qs.status === "pending") {
          this.setState({
            show_approved: false,
            show_pending: true,
            changeState: "pending",
          });
          if (qs.sort) {
            this.props.history.push(
              "?page=" + 1 + "&status=pending&sort=" + qs.sort
            );
          } else {
            this.props.history.push("?page=" + 1 + "&status=pending");
          }
          this.setState({
            currentPage: 1,
          });
        } else {
          this.setState({
            show_approved: false,
            show_pending: false,
            changeState: "all",
          });
          // if (qs.sort) {
          //   this.props.history.push(
          //     "?page=" + 1 + "&status=all&sort=" + qs.sort
          //   );
          // } else {
          //   this.props.history.push("?page=" + 1 + "&status=all");
          // }
        }
      }
      if (qs.page) {
        this.setState({
          currentPage: qs.page,
        });

        if (this.state.show_approved) {
          if (qs.sort) {
            this.props.history.push(
              "?page=" + qs.page + "&status=approved&sort=" + qs.sort
            );
          } else {
            this.props.history.push("?page=" + qs.page + "&status=approved");
          }
        } else if (this.state.show_pending) {
          if (qs.sort) {
            this.props.history.push(
              "?page=" + qs.page + "&status=pending&sort=" + qs.sort
            );
          } else {
            this.props.history.push("?page=" + qs.page + "&status=pending");
          }
        } else {
          if (qs.sort) {
            this.props.history.push(
              "?page=" + qs.page + "&status=all&sort=" + qs.sort
            );
          } else {
            this.props.history.push("?page=" + qs.page + "&status=all");
          }
        }
      } else {
        this.setState({
          show_pending: false,
          show_approved: false,
          changeState: "",
          currentPage: 1,
        });
        this.fetch_task_instances();
        this.props.history.push("?page=1&status=all");
      }
    }
    axios
      .get(
        global.config.apiGateway.URL +
          "/admin/tasks/instances?limit=" +
          this.state.limit,
        {
          headers: { Authorization: access_token },
          params: this.params,
        }
      )
      .then((response) => {
        // data = response.data.data;
        // console.log("data", response.data);
        // const total = response.data.total_results;
        // this.setState({ pageCount: Math.ceil(total / this.state.limit) });
        // console.log(total);
        this.setState({
          total_instances: response.data.total_results,
          total_pending: response.data.total_pending,
          total_approved: response.data.total_approved,
        });
        // console.log(this.total_instances, this.total_pending, this.total_approved)
        // data = response.data;
        // this.setState({ task_instances: data });
      })
      .catch((error) => {
        console.error(String(error));
      });
    this.fetchData(this.state.currentPage);
  }

  fetch_task_instances = () => {
    var access_token = "Bearer " + ReactSession.get("access_token");
    var data = [];

    axios
      .get(
        global.config.apiGateway.URL +
          "/admin/tasks/instances?limit=" +
          this.state.limit,
        {
          headers: { Authorization: access_token },
          params: this.params,
        }
      )
      .then((response) => {
        data = response.data.data;
        console.log("data", response.data);
        const total = response.data.total_results;
        this.setState({ pageCount: Math.ceil(total / this.state.limit) });
        console.log(total);
        this.setState({
          total_instances: response.data.total_results,
          total_pending: response.data.total_pending,
          total_approved: response.data.total_approved,
        });
        // console.log(this.total_instances, this.total_pending, this.total_approved)
        data = response.data;
        this.setState({ task_instances: data });
      })
      .catch((error) => {
        console.error(String(error));
      });
  };

  fetchData = (currentPage) => {
    var access_token = "Bearer " + ReactSession.get("access_token");

    if (this.state.changeState === "pending") {
      axios
        .get(
          global.config.apiGateway.URL +
            `/admin/tasks/instances?status=pending&page=${currentPage}&limit=${this.state.limit}`,
          {
            headers: { Authorization: access_token },
            params: this.params,
          }
        )
        .then((response) => {
          console.log(response.data)
          var data = response.data;
          const total = response.data.total_pending;
          this.setState({ pageCount: Math.ceil(total / this.state.limit) });
          this.setState({
            task_instances: data,
            // total_approved: response.data.total_approved,
            // total_instances: response.data.total_results,
            total_pending: response.data.total_pending,
          });
        })
        .catch((error) => {
          console.error(String(error));
        });
    } else if (this.state.changeState === "approved") {
      axios
        .get(
          global.config.apiGateway.URL +
            `/admin/tasks/instances?status=approved&page=${currentPage}&limit=${this.state.limit}`,
          {
            headers: { Authorization: access_token },
            params: this.params,
          }
        )
        .then((response) => {
          var data = response.data;
          const total = response.data.total_approved;
          this.setState({ pageCount: Math.ceil(total / this.state.limit) });
          // console.log("total approved fetch data: ", total);
          this.setState({
            task_instances: data,
            // total_instances: response.data.total_results,
            // total_pending: response.data.total_pending,
            total_approved: response.data.total_approved,
          });
        })
        .catch((error) => {
          console.error(String(error));
        });
    } else if (this.state.changeState === "searchByName") {
      axios
        .get(
          global.config.apiGateway.URL +
            `/admin/tasks/instances?fetchBy=name&name=${this.state.userName}&status=pending&page=${currentPage}&limit=${this.state.limit}`,
          {
            headers: { Authorization: access_token },
            params: this.params,
          }
        )
        .then((response) => {
          var data = response.data;
          const total = response.data.total_results;
          this.setState({ pageCount: Math.ceil(total / this.state.limit) });
          console.log(total);
          this.setState({
            task_instances: data,
            // total_instances: response.data.total_results,
            total_pending: response.data.total_pending,
            // total_approved: response.data.total_approved,
          });
        })
        .catch((error) => {
          console.error(String(error));
        });
    } else {
      axios
        .get(
          global.config.apiGateway.URL +
            `/admin/tasks/instances?page=${currentPage}&limit=${this.state.limit}`,
          {
            headers: { Authorization: access_token },
            params: this.params,
          }
        )
        .then((response) => {
          var data = response.data;
          const total = response.data.total_results;
          this.setState({ pageCount: Math.ceil(total / this.state.limit) });
          console.log(total);
          this.setState({
            task_instances: data,
            total_instances: response.data.total_results,
            total_pending: response.data.total_pending,
            total_approved: response.data.total_approved,
          });
        })
        .catch((error) => {
          console.error(String(error));
        });
    }

    return this.state.task_instances;
  };
  handlePageClick = (data) => {
    // console.log(data.selected);
    let currentPage = Number(data.selected) + 1;
    // curPage = currentPage;
    const dataFormServer = this.fetchData(currentPage);
    const qs = QueryString.parse(
      this.props.history.location.search.substring(1)
    );
    this.setState({ currentPage: currentPage });
    // console.log("qs", qs);
    if (qs.status === "pending") {
      if (qs.sort) {
        this.props.history.push(
          "?page=" + currentPage + "&status=pending&sort=" + qs.sort
        );
      } else {
        this.props.history.push("?page=" + currentPage + "&status=pending");
      }
    } else if (qs.status === "approved") {
      if (qs.sort) {
        this.props.history.push(
          "?page=" + currentPage + "&status=approved&sort=" + qs.sort
        );
      } else {
        this.props.history.push("?page=" + currentPage + "&status=approved");
      }
    } else {
      if (qs.sort) {
        this.props.history.push(
          "?page=" + currentPage + "&status=all&sort=" + qs.sort
        );
      } else {
        this.props.history.push("?page=" + currentPage + "&status=all");
      }
    }

    this.setState({ task_instances: dataFormServer.data });
  };

  handleSort = (e) => {
    console.log(e.target.value);
    this.props.history.push(
      "?page=" +
        this.state.currentPage +
        "&status=" +
        this.state.changeState +
        "&sort=" +
        e.target.value
    );
    this.setState({ sort: e.target.value });
  };

  generate_task_instance_list = () => {
    var task_instance_list = "";
    // console.log(this.state.task_instances);
    // console.log("task_instances: ", this.state.task_instances);

    const sorted =
      this.state.task_instances !== undefined &&
      this.state.task_instances.data !== undefined
        ? this.state.task_instances.data.sort((a, b) => {
            if (this.state.sort === "AscendingName") {
              return ("" + a.user_name).localeCompare(b.user_name);
            } else if (this.state.sort === "DescendingName") {
              return ("" + b.user_name).localeCompare(a.user_name);
            } else if (this.state.sort === "AscendingTime") {
              return (
                new Date(b.start_time).getTime() -
                new Date(a.start_time).getTime()
              );
            } else if (this.state.sort === "DescendingTime") {
              return (
                new Date(a.start_time).getTime() -
                new Date(b.start_time).getTime()
              );
            } else if (this.state.sort === "AscendingTask") {
              return a.id - b.id;
            } else if (this.state.sort === "DescendingTask") {
              return b.id - a.id;
            }
          })
        : [];

    // console.log("sorted:::", sorted);

    if (sorted.length) {
      // task_instance_list = this.state.task_instances
      task_instance_list = sorted
        .filter((task) => {
          if (this.state.searchTerm === "") {
            return task;
          } else if (
            task.user_name
              .toLowerCase()
              .includes(this.state.searchTerm.toLocaleLowerCase()) ||
            task.user_mobile_no
              .toLowerCase()
              .includes(this.state.searchTerm.toLowerCase())
          ) {
            return task;
          }
          return null;
        })
        .map((task) => {
          if (
            (this.state.show_pending && task.is_approved == null) ||
            (!this.state.show_pending && !this.state.show_approved) ||
            (this.state.show_approved && task.is_approved)
          ) {
            return (
              <div
                className="col-md-12 row list-group-item"
                key={task.id}
                style={{
                  background:
                    task.is_approved === true
                      ? "#a2ebb5"
                      : task.is_approved === false
                      ? "#ffa991"
                      : "#ffe9bd",
                }}
              >
                <div className="row">
                  <div className="col-md-4">
                    Task Instance ID : {task.id} <br></br> <br></br>
                    Start Time : {moment(task.start_time)
                      .local()
                      .format("LLL")}{" "}
                  </div>
                  <div className="col-md-4">
                    GP Name :{" "}
                    <Link to={"/partner_profile/" + task.user_id}>
                      {task.user_name + " " + "(" + task.user_mobile_no + ")"}
                    </Link>{" "}
                    <br></br> <br></br>
                    End Time :{" "}
                    {task.end_time
                      ? moment(task.end_time).local().format("LLL")
                      : ""}
                  </div>
                  <div className="col-md-2">
                    Task :{" "}
                    <Link to={"/task/" + task.task_id}>{task.title}</Link>
                  </div>
                  <div className="col-md-2">
                    <Link to={"/task_instance/" + task.id}>
                      <b>VIEW</b>
                    </Link>{" "}
                    <br></br> <br></br>
                    {task.is_approved === true && (
                      <p style={{ color: "green" }}>Approved✔️</p>
                    )}
                    {task.is_approved === false && (
                      <p style={{ color: "red" }}>Rejected❌</p>
                    )}
                    {task.is_approved === null && <p>Approval Pending⌛</p>}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px" }}></div>
              </div>
            );
          } else {
            return null;
          }
        });
    } else {
      task_instance_list = (
        <div>
          <h3>No task instances to show</h3>
        </div>
      );
    }
    return task_instance_list;
  };

  // count_tasks = () => {
  //   var approved = 0;
  //   var all = 0;
  //   var pending = 0;
  //   this.state.task_instances.map((task) => {
  //     if (task.is_approved) {
  //       approved++;
  //     }
  //     if (task.is_approved == null) {
  //       pending++;
  //     }
  //     all++;
  //     return null;
  //   });
  //   return {
  //     all: all,
  //     approved: approved,
  //     pending: pending,
  //   };
  // };

  hide_or_show_pending = () => {
    this.setState({
      show_pending: true,
      show_approved: false,
      changeState: "pending",
      currentPage: 1,
    });
    if (this.state.sort !== "") {
      this.props.history.push("?page=1&status=pending&sort=" + this.state.sort);
    } else {
      this.props.history.push("?page=1&status=pending");
    }
    var access_token = "Bearer " + ReactSession.get("access_token");
    var data = [];
    axios
      .get(
        global.config.apiGateway.URL +
          "/admin/tasks/instances?status=pending&page=1&limit=" +
          this.state.limit,
        {
          headers: { Authorization: access_token },
          params: this.params,
        }
      )
      .then((response) => {
        data = response.data;
        // console.log("data", response.data);
        const total = response.data.total_pending;
        this.setState({ pageCount: Math.ceil(total / this.state.limit) });
        console.log(total);
        this.setState({ task_instances: data, total_pending: response.data.total_pending });
      })
      .catch((error) => {
        console.error(String(error));
      });
  };

  hide_or_show_approved = () => {
    this.setState({
      show_pending: false,
      show_approved: true,
      changeState: "approved",
      currentPage: 1,
    });
    if (this.state.sort !== "") {
      this.props.history.push(
        "?page=1&status=approved&sort=" + this.state.sort
      );
    } else {
      this.props.history.push("?page=1&status=approved");
    }

    var access_token = "Bearer " + ReactSession.get("access_token");
    var data = [];

    axios
      .get(
        global.config.apiGateway.URL +
          "/admin/tasks/instances?status=approved&page=1&limit=" +
          this.state.limit,
        {
          headers: { Authorization: access_token },
          params: this.params,
        }
      )
      .then((response) => {
        data = response.data;
        const total = response.data.total_approved;
        this.setState({ pageCount: Math.ceil(total / this.state.limit) });
        console.log(total);
        // console.log(this.total_instances, this.total_pending, this.total_approved)
        this.setState({ task_instances: data });
      })
      .catch((error) => {
        console.error(String(error));
      });
  };

  hide_or_show_all = () => {
    if (this.state.sort !== "") {
      this.props.history.push("?page=1&status=all&sort=" + this.state.sort);
    } else {
      this.props.history.push("?page=1&status=all");
    }

    this.setState({
      show_pending: false,
      show_approved: false,
      changeState: "all",
      currentPage: 1,
    });

    // this.handlePageClick({selected: 0})
    this.fetch_task_instances();
  };

  componentDidUpdate() {
    this.task_instance_list = this.generate_task_instance_list();
    if (this.props.history.location.search === "") {
      this.props.history.goBack();
      return;
    }
    const qs = QueryString.parse(
      this.props.history.location.search.substring(1)
    );

    // console.log(qs);
    // if (qs.page == undefined) {
    //   console.log(this.props);
    //   this.props.history.goBack();
    //   return;
    // }
    // console.log("qspage", qs.page)
    if (this.state.currentPage !== qs.page) {
      this.setState({
        currentPage: qs.page,
      });
    }
    if (this.state.changeState !== qs.status) {
      if (qs.status === "approved") {
        this.setState({
          show_pending: false,
          show_approved: true,
          changeState: "approved",
        });
      } else if (qs.status === "pending") {
        this.setState({
          show_approved: false,
          show_pending: true,
          changeState: "pending",
        });
      } else {
        this.setState({
          show_approved: false,
          show_pending: false,
          changeState: "all",
        });
      }
    }
  }

  filterByDate = () => {
    const startDateInput = document.getElementById("start_date_input")
    const endDateInput = document.getElementById("end_date_input");
    if(startDateInput.value == '' || endDateInput.value==""){
      alert("date range should have both start date and end date")
      return 
    }
    this.params["filter_start_date"] = startDateInput.value;
    this.params["filter_end_date"] = endDateInput.value
    this.setState({
      startDate: startDateInput.value,
      endDate: endDateInput.value
    })
    console.log(startDateInput.value);
    console.log(endDateInput.value);
    this.fetch_task_instances()
  };

  render() {
    if (!this.state.logged_in) {
      return (
        <div>
          <h5>Not logged in !</h5>
        </div>
      );
    }
    var task_instance_list = this.generate_task_instance_list();
    // const { all, pending, approved } = this.count_tasks();
    // const { task_instances } = this.state;
    // console.log(task_instances);
    return (
      <div className="row">
        <div className="col-md-12 page-header text-center">
          <h2>
            {this.state.job_id && (
              <small>
                <Link to={"/job/" + this.state.job_id}>
                  Job ID - {this.state.job_id}
                </Link>
              </small>
            )}
            {this.state.task_id && (
              <small>
                <Link to={"/task/" + this.state.task_id}>
                  Task ID - {this.state.task_id}
                </Link>
              </small>
            )}
            {this.state.job_instance_id && (
              <small>
                <Link to={"/job_instance/" + this.state.job_instance_id}>
                  Job Instance ID - {this.state.job_instance_id}
                </Link>
              </small>
            )}
            Task Instances
          </h2>
        </div>

        <hr></hr>
        <div className="col-md-12" style={{ marginTop: "3px" }}>
          <div className="col-md-4">
            <h5>Total Task Instances : {this.state.total_instances}</h5>
          </div>
          <div className="col-md-4">
            <h5>
              Task Instances Pending for Approval : {this.state.total_pending}
            </h5>
          </div>
          <div className="col-md-2">
            <h5>Approved Task Instances : {this.state.total_approved}</h5>
          </div>
          <div className="col-md-2">
            {/* <button className="btn btn-xs">Sort By Name</button> */}

            <select
              style={{ marginTop: "3px" }}
              defaultValue="Sort By TaskID"
              onChange={this.handleSort}
            >
              <option disabled value="Sort By TaskID">
                Sort By TaskID
              </option>
              <option value="AscendingTask">Ascending</option>
              <option value="DescendingTask">Descending</option>
            </select>
          </div>
        </div>

        <div className="col-md-12" style={{ marginBottom: "25px" }}>
          <div className="col-md-4">
            <button className="btn btn-xs" onClick={this.hide_or_show_all}>
              Show All
            </button>
          </div>
          <div className="col-md-4">
            <button className="btn btn-xs" onClick={this.hide_or_show_pending}>
              Show Pending
            </button>
          </div>
          <div className="col-md-2">
            <button className="btn btn-xs" onClick={this.hide_or_show_approved}>
              Show Approved
            </button>
          </div>
          <div className="col-md-2">
            {/* <button className="btn btn-xs">Sort By Time</button> */}
            <select defaultValue="Sort By Time" onChange={this.handleSort}>
              <option disabled value="Sort By Time">
                Sort By Time
              </option>
              <option value="AscendingTime">Newest</option>
              <option value="DescendingTime">Oldest</option>
            </select>
          </div>
        </div>
        <div className="til_limitSelect">
          <select
            style={{ marginTop: "3px" }}
            defaultValue="10"
            onChange={this.handleLimitChange}
          >
            <option value="10" disabled>
              select limit
            </option>
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <button className="btn btn-default" onClick={this.updateLimit}>
            Update Limit
          </button>
        </div>
        <div className="til_dateRangeInput form-group">
          <label htmlFor="start_date_input">Start Date</label>
          <input className="form-control" type="date" name="start_date" id="start_date_input" />
          <label htmlFor="end_date_input">End Date</label>
          <input className="form-control" type="date" name="end_date" id="end_date_input" />
          <button className="btn btn-default" onClick={this.filterByDate} style={{marginTop: "8px"}}>
            Apply
          </button>
        </div>
        {task_instance_list}
        <div>
          <ReactPaginate
            forcePage={Number(this.state.currentPage) - 1}
            previousLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            pageCount={this.state.pageCount ? this.state.pageCount : 0}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
            // activePage={Number(QueryString.parse(this.props.history.location.search.substring(1)).page)
            //   ? Number(QueryString.parse(this.props.history.location.search.substring(1)).page) : 1}
          />
          {this.state.total && console.log("total", this.state.total / 10)}
        </div>
      </div>
    );
  }
}

export default withRouter(UserSpecificTasks);
