import React from "react";
import "./bankDetails.css";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline.js";

function BankDetails(props) {
  const approvedBAControls = (isApproved, docNumber) => {
    if (isApproved === "Accepted") {
      return (
        <p
          style={{
            marginTop: "30px",
            fontSize: "15px",
            textAlign: "center",
          }}
          className="col-md-8 text-success"
        >
          Approved <CheckCircleOutlineIcon />
        </p>
      );
    } else if (isApproved === "Rejected") {
      return (
        <p
          style={{
            marginTop: "30px",
            fontSize: "15px",
            textAlign: "center",
          }}
          className="col-md-8 text-warning"
        >
          Rejected
        </p>
      );
    } else if (isApproved === undefined || isApproved === "No entry") {
      return (
        <p
          style={{
            marginTop: "30px",
            fontSize: "15px",
            textAlign: "center",
          }}
          className="col-md-8 text-warning"
        >
          No Details Specified
        </p>
      );
    } else {
      return (
        <div style={{ marginTop: "30px" }} className="col-md-12">
          <div className="col-md-3"></div>
          <div className="col-md-9">
            <button
              onClick={() => {
                props.updateBankVerification(
                  props.payment_method_id,
                  true,
                  props.user_id
                );
              }}
              className="button_approve"
            >
              Approve
            </button>
            <button
              onClick={() => {
                props.updateBankVerification(
                  props.payment_method_id,
                  false,
                  props.user_id
                );
              }}
              className="button_reject"
            >
              Reject
            </button>
          </div>
        </div>
      );
    }
  };
  const approvedControls = (isApproved, docNumber) => {
    if (isApproved === true) {
      return (
        <p
          style={{
            marginTop: "30px",
            fontSize: "15px",
            textAlign: "center",
          }}
          className="col-md-8 text-success"
        >
          Approved <CheckCircleOutlineIcon />
        </p>
      );
    } else if (isApproved === false) {
      return (
        <p
          style={{
            marginTop: "30px",
            fontSize: "15px",
            textAlign: "center",
          }}
          className="col-md-8 text-warning"
        >
          Rejected
        </p>
      );
    } else {
      return (
        <div style={{ marginTop: "30px" }} className="col-md-12">
          <div className="col-md-3"></div>
          <div className="col-md-9">
            <button
              onClick={() => {
                props.approveDoc(docNumber, isApproved);
              }}
              className="button_approve"
            >
              Approve
            </button>
            <button
              onClick={() => {
                props.rejectDoc(docNumber, isApproved);
              }}
              className="button_reject"
            >
              Reject
            </button>
          </div>
        </div>
      );
    }
  };
  return (
    <div className="">
      <div className="subStatus">
        Status:{" "}
        <span>
          {props.isApprovedBank !== "Rejected" &&
          props.isApprovedUpi &&
          props.isApprovedCC &&
          props.isApprovedPassbook
            ? "Done"
            : "Not Done"}
        </span>
        {/* {console.log(props.payment_method_id)} */}
      </div>
      <div className="kyc_card" style={{ overflow: "hidden" }}>
        <div className="card-header col-md-12" id="headingOne">
          <h4
            className="mb-0"
            type="button"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
            style={{ fontWeight: 600 }}
          >
            Bank Account Details
          </h4>
        </div>
        <div
          id="collapseOne"
          className="collapse col-md-12"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
          style={{ overflow: "hidden", paddingLeft: "0px" }}
        >
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              Account Holder Name
            </h4>
            <input
              className="col-md-9"
              placeholder="User Name"
              value={props.ah_name}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              Account Number
            </h4>
            <input
              className="col-md-9"
              placeholder="Not Provided"
              value={props.ah_number}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              Branch Name
            </h4>
            <input
              className="col-md-9"
              placeholder="0000000000"
              value={props.branch}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              IFSC
            </h4>
            <input
              className="col-md-9"
              placeholder="0000000000"
              value={props.ifsc}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          {approvedBAControls(props.isApprovedBank)}
        </div>
      </div>
      <div className="kyc_card" style={{ overflow: "hidden" }}>
        <div className="card-header col-md-12" id="headingTwo">
          <h4
            className="mb-0"
            type="button"
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
            style={{ fontWeight: 600 }}
          >
            Cancelled Check
          </h4>
        </div>
        <div
          id="collapseTwo"
          className="collapse col-md-12"
          aria-labelledby="headingTwo"
          data-parent="#accordionExample"
          style={{ overflow: "hidden", paddingLeft: "0px" }}
        >
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              Photo
            </h4>
            <img
              className="col-md-4"
              src={props.cancelledCheck}
              alt="cc front"
            />
          </div>
          {approvedControls(props.isApprovedCC, 2)}
        </div>
      </div>
      <div className="kyc_card" style={{ overflow: "hidden" }}>
        <div className="card-header col-md-12" id="headingThree">
          <h4
            className="mb-0"
            type="button"
            data-toggle="collapse"
            data-target="#collapseThree"
            aria-expanded="true"
            aria-controls="collapseThree"
            style={{ fontWeight: 600 }}
          >
            Passbook
          </h4>
        </div>
        <div
          id="collapseThree"
          className="collapse col-md-12"
          aria-labelledby="headingThree"
          data-parent="#accordionExample"
          style={{ overflow: "hidden", paddingLeft: "0px" }}
        >
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              Photo
            </h4>
            <img
              className="col-md-4"
              src={props.passbook}
              alt="passbook front"
            />
          </div>
          {approvedControls(props.isApprovedPassbook, 3)}
        </div>
      </div>
      <div className="kyc_card" style={{ overflow: "hidden" }}>
        <div className="card-header col-md-12" id="headingFour">
          <h4
            className="mb-0"
            type="button"
            data-toggle="collapse"
            data-target="#collapseFour"
            aria-expanded="true"
            aria-controls="collapseFour"
            style={{ fontWeight: 600 }}
          >
            UPI ID
          </h4>
        </div>
        <div
          id="collapseFour"
          className="collapse col-md-12"
          aria-labelledby="headingFour"
          data-parent="#accordionExample"
          style={{ overflow: "hidden", paddingLeft: "0px" }}
        >
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              ID
            </h4>
            <input
              className="col-md-9"
              placeholder="0000000000"
              value={props.upi}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          {approvedControls(props.isApprovedUpi, 0)}
        </div>
      </div>
    </div>
  );
}

export default BankDetails;
