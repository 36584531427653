import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { ReactSession } from "react-client-session";
import "./survey.css";
var _isMounted;
function Survey(props) {
    const [surveyResponses, setSurveyResponses] = useState([]);
    useEffect(() => {
        _isMounted = true;
        if (_isMounted) {
            var access_token = "Bearer " + ReactSession.get("access_token");
            axios
                .get(
                    global.config.apiGateway.URL +
                        "/admin/users/" +
                        props.user_id +
                        "/survey-responses",
                    {
                        headers: { Authorization: access_token },
                    }
                )
                .then((response) => {
                    setSurveyResponses(response.data);
                })
                .catch((error) => {
                    console.error(String(error));
                });
        }

        return () => {
            // cleanup
            _isMounted = false;
        };
    }, []);

    return (
      <div className="survey">
        <div className="subStatus">
          Status:{" "}
          <span>{surveyResponses.length >= 1 ? "Done" : "Not Done"}</span>
        </div>
        <div className="survey_subsection">
          {surveyResponses.map((surveyResponse) => (
            <div key={surveyResponse.position * 147}>
              <p
                className="survey_counter"
                data-toggle="collapse"
                data-target={"#collapse" + surveyResponse.position}
              >
                Question {surveyResponse.position}
              </p>
              <div
                className="collapse"
                id={"collapse" + surveyResponse.position}
              >
                <p className="survey_question">{surveyResponse.question}</p>
                <p className="survey_response">{surveyResponse.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
}

export default Survey;
