import React from "react";
import "./addressProof.css";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline.js";
import placeholderImage from "./static/placeholderImg.png";
import moment from "moment";
import { useEffect } from "react";
let formattedDate = "";
function AddressProof(props) {
  useEffect(() => {
    let date = props.aadhar.dob;
    formattedDate = moment(date).format("MM/DD/yy");
  }, [props.aadhar]);

  const approvedControls = (isApproved, docNumber) => {
    if (isApproved === true) {
      return (
        <p
          style={{
            marginTop: "30px",
            fontSize: "15px",
            textAlign: "center",
          }}
          className="col-md-8 text-success"
        >
          Approved <CheckCircleOutlineIcon />
        </p>
      );
    } else if (isApproved === false) {
      return (
        <p
          style={{
            marginTop: "30px",
            fontSize: "15px",
            textAlign: "center",
          }}
          className="col-md-8 text-warning"
        >
          Rejected
        </p>
      );
    } else {
      return (
        <div style={{ marginTop: "30px" }} className="col-md-12">
          <div className="col-md-3"></div>
          <div className="col-md-9">
            <button
              onClick={() => {
                props.approveDoc(docNumber, isApproved);
              }}
              className="button_approve"
            >
              Approve
            </button>
            <button
              onClick={() => {
                props.rejectDoc(docNumber, isApproved);
              }}
              className="button_reject"
            >
              Reject
            </button>
          </div>
        </div>
      );
    }
  };
  return (
    <div className="">
      <div className="subStatus">
        Status:
        <span>
          {props.isApprovedAadhar ||
          props.isApprovedDl ||
          props.isApprovedVoterId
            ? "Done"
            : "Not Done"}
        </span>
      </div>
      <div className="kyc_card" style={{ overflow: "hidden" }}>
        <div className="card-header col-md-12" id="headingOne">
          <h4
            className="mb-0"
            type="button"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
            style={{ fontWeight: 600 }}
          >
            Aadhar Card
          </h4>
        </div>

        <div
          id="collapseOne"
          className="collapse col-md-12"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
          style={{ overflow: "hidden", paddingLeft: "0px" }}
        >
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              Aadhar Number
            </h4>
            <input
              className="col-md-9"
              placeholder="Not Provided"
              value={props.aadhar?.number}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              Aadhar Name
            </h4>
            <input
              className="col-md-9"
              placeholder="Not Provided"
              value={props.aadhar?.name}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              Date of Birth
            </h4>
            <input
              className="col-md-3"
              placeholder="Not Provided"
              value={formattedDate}
              readOnly
              style={{ marginTop: "8px" }}
            />
            <h4
              className="col-md-3"
              style={{ fontWeight: 400, textAlign: "center" }}
            >
              Gender
            </h4>
            <input
              className="col-md-3"
              placeholder="Not Provided"
              value={props.aadhar?.gender}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          <h4
            className="col-md-12"
            style={{ fontWeight: 600, marginTop: "20px" }}
          >
            Address
          </h4>
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              House No.
            </h4>
            <input
              className="col-md-3"
              placeholder="Not Provided"
              value={props.aadhar?.house}
              readOnly
              style={{ marginTop: "8px" }}
            />
            <h4
              className="col-md-3"
              style={{ fontWeight: 400, textAlign: "center" }}
            >
              Landmark
            </h4>
            <input
              className="col-md-3"
              placeholder="Not Provided"
              value={props.aadhar?.landmark}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              Village
            </h4>
            <input
              className="col-md-9"
              placeholder="Not Provided"
              value={props.aadhar?.village}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              Location
            </h4>
            <input
              className="col-md-3"
              placeholder="Not Provided"
              value={props.aadhar?.location}
              readOnly
              style={{ marginTop: "8px" }}
            />
            <h4
              className="col-md-3"
              style={{ fontWeight: 400, textAlign: "center" }}
            >
              Subdivision
            </h4>
            <input
              className="col-md-3"
              placeholder="Not Provided"
              value={props.aadhar?.subdivision}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              District
            </h4>
            <input
              className="col-md-3"
              placeholder="Not Provided"
              value={props.aadhar?.district}
              readOnly
              style={{ marginTop: "8px" }}
            />
            <h4
              className="col-md-3"
              style={{ fontWeight: 400, textAlign: "center" }}
            >
              State
            </h4>
            <input
              className="col-md-3"
              placeholder="Not Provided"
              value={props.aadhar?.state}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              Pin Code
            </h4>
            <input
              className="col-md-3"
              placeholder="Not Provided"
              value={props.aadhar?.pincode}
              readOnly
              style={{ marginTop: "8px" }}
            />
            <h4
              className="col-md-3"
              style={{ fontWeight: 400, textAlign: "center" }}
            >
              Post office
            </h4>
            <input
              className="col-md-3"
              placeholder="Not Provided"
              value={props.aadhar?.post_office}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            {approvedControls(props.aadhar?.verified)}
          </div>
        </div>
      </div>
      <div className="kyc_card" style={{ overflow: "hidden" }}>
        <div className="card-header col-md-12" id="headingTwo">
          <h4
            className="mb-0"
            type="button"
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
            style={{ fontWeight: 600 }}
          >
            Driving License
          </h4>
        </div>
        <div
          id="collapseTwo"
          className="collapse col-md-12"
          aria-labelledby="headingTwo"
          data-parent="#accordionExample"
          style={{ overflow: "hidden", paddingLeft: "0px" }}
        >
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              License Number
            </h4>
            <input
              className="col-md-9"
              placeholder="Not Provided"
              value={props.dl?.number}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              Date of Birth
            </h4>
            <input
              className="col-md-9"
              placeholder="Not Provided"
              value={formattedDate}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              Photos
            </h4>
            <img
              className="col-md-4"
              src={props.dl?.front || placeholderImage}
              alt="dl front"
            />
            <img
              className="col-md-4"
              src={props.dl?.back || placeholderImage}
              alt="dl back"
            />
          </div>
          {approvedControls(props.dl?.isApproved, 5)}
        </div>
      </div>
      <div className="kyc_card" style={{ overflow: "hidden" }}>
        <div className="card-header col-md-12" id="headingThree">
          <h4
            className="mb-0"
            type="button"
            data-toggle="collapse"
            data-target="#collapseThree"
            aria-expanded="true"
            aria-controls="collapseThree"
            style={{ fontWeight: 600 }}
          >
            Voter Id
          </h4>
        </div>
        <div
          id="collapseThree"
          className="collapse col-md-12"
          aria-labelledby="headingThree"
          data-parent="#accordionExample"
          style={{ overflow: "hidden", paddingLeft: "0px" }}
        >
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              Name
            </h4>
            <input
              className="col-md-9"
              placeholder="User Name"
              value={props.user_name}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              ID Number
            </h4>
            <input
              className="col-md-9"
              placeholder="Not Provided"
              value={props.voter?.number}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              Photos
            </h4>
            <img
              className="col-md-4"
              src={props.voter?.front || placeholderImage}
              alt="voter front"
            />
            <img
              className="col-md-4"
              src={props.voter?.back || placeholderImage}
              alt="voter back"
            />
          </div>
          {approvedControls(props.voter?.isApproved, 6)}
        </div>
      </div>
    </div>
  );
}

export default AddressProof;
