import React from "react";
import "./panCard.css";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline.js";
import { useEffect } from "react";
import moment from "moment";
let formattedDate = "";
function PanCard(props) {
  useEffect(() => {
    let date = props.dob;
    formattedDate = moment(date).format("MM/DD/yy");
  }, [props.dob]);
  const approvedControls = (
    isApprovedPanNumber,
    isApprovedPanPhoto,
    docNumber
  ) => {
    if (isApprovedPanNumber === true && isApprovedPanPhoto === true) {
      return (
        <p
          style={{
            marginTop: "30px",
            fontSize: "15px",
            textAlign: "center",
          }}
          className="col-md-8 text-success"
        >
          Approved <CheckCircleOutlineIcon />
        </p>
      );
    } else if (isApprovedPanNumber === false && isApprovedPanPhoto === false) {
      return (
        <p
          style={{
            marginTop: "30px",
            fontSize: "15px",
            textAlign: "center",
          }}
          className="col-md-8 text-warning"
        >
          Rejected
        </p>
      );
    } else {
      return (
        <div style={{ marginTop: "30px" }} className="col-md-12">
          <div className="col-md-3"></div>
          <div className="col-md-9">
            <button
              onClick={() => {
                approvePanDoc(isApprovedPanNumber, isApprovedPanPhoto);
              }}
              className="button_approve"
            >
              Approve
            </button>
            <button
              onClick={() => {
                rejectPanDoc(isApprovedPanNumber, isApprovedPanPhoto);
              }}
              className="button_reject"
            >
              Reject
            </button>
          </div>
        </div>
      );
    }
  };
  const approvePanDoc = (isApprovedPanNumber, isApprovedPanPhoto) => {
    if (isApprovedPanNumber && !isApprovedPanPhoto) {
      props.approveDoc(4, isApprovedPanPhoto);
    } else if (!isApprovedPanNumber && !isApprovedPanPhoto) {
      // approvePanNumber()
      props.approveDoc(4, isApprovedPanPhoto);
    }
    alert(
      "pan card approved: " + isApprovedPanNumber + " " + isApprovedPanPhoto
    );
  };
  const rejectPanDoc = (isApprovedPanNumber, isApprovedPanPhoto) => {
    if (isApprovedPanNumber && !isApprovedPanPhoto) {
      props.rejectDoc(4, isApprovedPanPhoto);
    } else if (!isApprovedPanNumber && !isApprovedPanPhoto) {
      // rejectPanNumber()
      props.rejectDoc(4, isApprovedPanPhoto);
    }
    alert("pan card rejecc: " + isApprovedPanNumber + " " + isApprovedPanPhoto);
  };

  return (
    <div className="">
      <div className="subStatus">
        Status:
        <span>{props.pan && props.isApprovedPan ? "Done" : "Not Done"}</span>
      </div>
      <div className="kyc_card" style={{ overflow: "hidden" }}>
        <div className="card-header col-md-12" id="headingThree">
          <h4
            className="mb-0"
            type="button"
            data-toggle="collapse"
            data-target="#collapseThree"
            aria-expanded="true"
            aria-controls="collapseThree"
            style={{ fontWeight: 600 }}
          >
            PAN Card
          </h4>
        </div>
        <div
          id="collapseThree"
          className="collapse col-md-12"
          aria-labelledby="headingThree"
          data-parent="#accordionExample"
          style={{ overflow: "hidden", paddingLeft: "0px" }}
        >
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              Name
            </h4>
            <input
              className="col-md-9"
              placeholder="User Name"
              value={props.user_name}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              PAN Number
            </h4>
            <input
              className="col-md-9"
              placeholder="Not Provided"
              value={props.panNumber}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              Date of Birth
            </h4>
            <input
              className="col-md-9"
              placeholder="Not Provided"
              value={formattedDate}
              readOnly
              style={{ marginTop: "8px" }}
            />
          </div>
          <div className="col-md-12" style={{ paddingLeft: "0px" }}>
            <h4 className="col-md-3" style={{ fontWeight: 400 }}>
              Pan Card Photo
            </h4>
            <img
              className="col-md-4"
              src={props.panCardFront}
              alt="pan front"
            />
          </div>
          {approvedControls(props.isApprovedPan, props.isApprovedDoc, 4)}
        </div>
      </div>
    </div>
  );
}
export default PanCard;
