import moment from "moment";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx/xlsx.mjs";

const Table = ({
  data,
  transactionMonth,
  paginateData,
  setPaginateData,
  fetchData,
  transactionType,
}) => {
  const [transactions, setTransactions] = useState([]);
  const [formatData, setFormatData] = useState([]);
  const [transGenerated, setTransGenerated] = useState("all");
  const [newLimit, setNewLimit] = useState(paginateData.limit);
  const tableColumns = [
    "ID",
    "User ID",
    "Username",
    "Entity type",
    "Amount",
    "Created at",
    "Paid on",
    "Payment details ID",
    "Payment status",
    "Trans generated",
  ];
  const [dataKeys] = useState([
    "id",
    "user_id",
    "username",
    "entity_type",
    "amount",
    "created_at",
    "paid_on",
    "payment_details_id",
    "payment_status",
    "is_manually_added",
  ]);

  useEffect(() => {
    const sortedData = data.sort((a, b) => a.id - b.id);
    const modifiedData = sortedData.map((row) => {
      const newRow = {};
      dataKeys.forEach((column) => {
        // converts date into local timezone
        if (column === "created_at" || column === "paid_on") {
          if (row[column] !== null) {
            newRow[column] = moment(row[column]).local().format("LLL");
          }
        } else if (column === "is_manually_added") {
          newRow[column] = row[column] ? "Manual" : "Automatic";
        } else newRow[column] = row[column];
        if (row[column] === null) newRow[column] = "";
      });
      return newRow;
    });
    setFormatData(modifiedData);
    setTransactions(modifiedData);
  }, [data, dataKeys]);

  //Event handlers
  const handleOnChangeLimit = () => {
    setPaginateData({ ...paginateData, limit: newLimit });
    fetchData(1, newLimit);
  };

  const handleOnChange = (e) => {
    setTransGenerated(e.target.value);

    if (e.target.value === "all") setTransactions(formatData);
    else if (e.target.value === "manual")
      setTransactions(
        formatData.filter((item) => item.is_manually_added === "Manual")
      );
    else if (e.target.value === "automatic")
      setTransactions(
        formatData.filter((item) => item.is_manually_added === "Automatic")
      );
  };

  const handleDownloadTransactions = () => {
    let sheet = XLSX.utils.json_to_sheet(transactions);
    let workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      sheet,
      `${transactionMonth}-${transGenerated.toLowerCase()}-page_${
        paginateData.currentPage
      }`
    );
    XLSX.writeFile(
      workbook,
      `${transactionType}-${transactionMonth}-${transGenerated.toLowerCase()}_transactions-page_${
        paginateData.currentPage
      }-limit_${paginateData.limit}.xlsx`
    );
  };

  return (
    <div className="table-responsive ">
      {/* transaction generated type filter */}
      <div className="form-group pull-right">
        <label
          htmlFor="transaction-generated"
          className="col-sm-2 control-label"
          style={{ whiteSpace: "nowrap" }}
        >
          Transaction generated
        </label>
        <div className="col-sm-10">
          <select
            name="transaction-generated"
            id="transaction-generated"
            className="pull-right"
            required
            onChange={(e) => handleOnChange(e)}
            value={transGenerated}
          >
            <option value="all">-- All --</option>
            <option value="manual">Manual</option>
            <option value="automatic">Automatic</option>
          </select>
        </div>
      </div>
      {/* ----> transaction generated type filter Ends */}
      {/* limit control */}
      <div className="col-lg-2 col-sm-3 pull-right">
        <div className="input-group">
          <input
            type="number"
            className="form-control"
            name="limit"
            id="limit"
            value={newLimit}
            onChange={(e) => setNewLimit(e.target.value)}
          />
          <span className="input-group-btn">
            <button
              className="btn btn-default"
              type="button"
              onClick={handleOnChangeLimit}
            >
              Set limit
            </button>
          </span>
        </div>
      </div>
      {/* ----> limit Control Ends */}
      {/* table */}
      <table className="table table-striped table-hover table-bordered table-condensed">
        <thead>
          <tr>
            {tableColumns.map((column, index) => {
              return <th key={index}>{column}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {transactions.map((rowData, index) => (
            <tr key={index}>
              {dataKeys.map((column, index) => (
                <td key={index}>{rowData[column]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {/* ----> table Ends */}
      <button
        type="button"
        className="btn btn-default"
        onClick={handleDownloadTransactions}
        disabled={transactions.length === 0 ? "disabled" : ""}
      >
        {`Download ${transGenerated.toLowerCase()} transactions`}
      </button>
    </div>
  );
};

export default Table;
