import React, { Component } from "react";
import { ReactSession } from "react-client-session";
import { Link } from "react-router-dom";
import axios from "axios";
import Switch from "./Switch";
import ReactPlayer from "react-player";
import UserSpecificTasks from "./UserSpecificTasks";

class PartnerProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: props.match.params.user_id,
      found: false,
      logged_in: false,
      profile_data: null,
      value: false,
      kyc_status: false,
      partner_role_mapping: []
    };

    if (
      typeof ReactSession.get("username") !== "undefined" &&
      typeof ReactSession.get("access_token") !== "undefined"
    ) {
      this.state.logged_in = true;
      this.state.username = ReactSession.get("username");
    }
    this.fetch_profile = this.fetch_profile.bind(this);
    this.generate_profile_data = this.generate_profile_data.bind(this);
    this.toggle_active_status = this.toggle_active_status.bind(this);
  }
  access_token = "Bearer " + ReactSession.get("access_token");
  componentDidMount() {
    if (this.state.logged_in) this.fetch_profile();
    axios
      .get(
        global.config.apiGateway.URL +
          `/admin/users/kycs?page=1&user_id=${this.state.user_id}`,
        {
          headers: {
            Authorization: this.access_token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        this.setState({
          kyc_status: response.data.data[0].kyc,
        });
      })
      .catch((error) => {
        console.error(String(error));
      });
  }

  fetch_profile = () => {
    var access_token = "Bearer " + ReactSession.get("access_token");
    var data = [];
    var params = {
      user_id: this.state.user_id,
    };
    axios
      .get(
        global.config.apiGateway.URL +
          "/admin/users/" +
          this.state.user_id +
          "/details",
        {
          headers: { Authorization: access_token },
          params: params,
        }
      )
      .then((response) => {
        data = response.data;
        console.log(data);
        this.setState({
          profile_data: data,
          found: true,
          value: data.is_active,
        });
      })
      .catch((error) => {
        console.error(String(error));
      });
      axios
        .get(global.config.apiGateway.URL + "/admin/partner-role-mapping", {
          headers: {
            Authorization: access_token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("Fetched mapping");
            this.setState({
              partner_role_mapping: response.data,
            });
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.error(String(error));
        });
  };


  switchRole = () => {
    let confirm = window.confirm("Are you sure to switch role for this user?");
    if (!confirm) {
      let role_select = document.getElementById("partner_role_select");
      if (!role_select) {
        console.log("no select in dom");
      } else {
        role_select.value = this.state.profile_data.partner_role;
      }
      return;
    }
    let access_token = "Bearer " + ReactSession.get("access_token");
    let formdata = new FormData();
    let role_select = document.getElementById("partner_role_select");
    if (!role_select) {
      console.log("no select in dom");
      return;
    }
    let new_role_id = role_select.value;
    formdata.append("new_role_id", new_role_id);
    axios
      .put(
        global.config.apiGateway.URL +
          "/admin/users/" +
          this.state.user_id +
          "/partner-role",
        formdata,
        {
          headers: { Authorization: access_token },
        }
      )
      .then((response) => {
        alert(response.data.data.user_name + "'s role is changed");
        this.fetch_profile();
      })
      .catch((error) => {
        console.error(String(error));
      });
  };

  toggle_active_status = () => {
    var access_token = "Bearer " + ReactSession.get("access_token");

    this.setState({
      value: !this.state.value,
    });

    if (window.confirm("Are you sure, you want to continue?")) {
      var formdata = new FormData();
      formdata.append("is_active", !this.state.value);
      axios
        .put(
          global.config.apiGateway.URL +
            "/admin/users/" +
            this.state.user_id +
            "/status",
          formdata,
          {
            headers: { Authorization: access_token },
          }
        )
        .then((response) => {
          // console.log(response.data);
        })
        .catch((error) => {
          console.error(String(error));
        });
    } else {
      this.setState({
        value: this.state.value,
      });
    }
  };

  generate_profile_data = () => {
    var profile_data;
    profile_data = (
      <div className="col-md-8 row">
        <div className="col-md-12 row">
          <div className="col-md-4">
            <h4>Active Status :</h4>
          </div>
          <div className="col-md-4">
            <Switch
              isOn={this.state.value}
              handleToggle={this.toggle_active_status}
            />
            <h5>{this.state.value ? "Active" : "Inactive"}</h5>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-4">
            <h4>First Name :</h4>
          </div>
          <div className="col-md-8">
            <h4>{this.state.profile_data.first_name}</h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-4">
            <h4>Last Name :</h4>
          </div>
          <div className="col-md-8">
            <h4>{this.state.profile_data.last_name}</h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-4">
            <h4>Phone Number :</h4>
          </div>
          <div className="col-md-8">
            <h4>{this.state.profile_data.phone_no}</h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-4">
            <h4>Ongoing Job Instances :</h4>
          </div>
          <div className="col-md-8">
            <h4>{this.state.profile_data.ongoing_jobs}</h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-4">
            <h4>Completed Jobs :</h4>
          </div>
          <div className="col-md-8">
            <h4 style={{ color: "green" }}>
              {this.state.profile_data.completed_jobs}
            </h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-4">
            <h4>Approved Tasks :</h4>
          </div>
          <div className="col-md-8">
            <h4 style={{ color: "green" }}>
              {this.state.profile_data.approved_tasks}
            </h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-4">
            <h4>Rejected Tasks :</h4>
          </div>
          <div className="col-md-8">
            <h4 style={{ color: "red" }}>
              {this.state.profile_data.rejected_tasks}
            </h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-4">
            <h4>Tasks Pending for Approval :</h4>
          </div>
          <div className="col-md-8">
            <h4>{this.state.profile_data.pending_tasks}</h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-4">
            <h4>Incomplete Tasks :</h4>
          </div>
          <div className="col-md-8">
            <h4>{this.state.profile_data.incomplete_tasks}</h4>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-4">
            <h4>Total Payment :</h4>
          </div>
          <div className="col-md-8">
            <h4>
              {" "}
              ₹ {this.state.profile_data.total_payment}{" "}
              <Link
                to={"/partner_profile/" + this.state.user_id + "/transactions"}
              >
                View All Transactions
              </Link>
            </h4>
          </div>
        </div>
        <div
          className="col-md-12 row"
          title={
            (this.state.partner_role_mapping &&
              this.state.partner_role_mapping[
                this.state.profile_data.partner_role - 1
              ] &&
              this.state.partner_role_mapping[
                this.state.profile_data.partner_role - 1
              ].label) ||
            ""
          }
        >
          {/* {console.log(
            this.state.partner_role_mapping[
              this.state.profile_data.partner_role
            ].label
          )} */}
          <div className="col-md-4">
            <h4>Partner Role :</h4>
          </div>
          <div className="col-md-8">
            <select
              name="partner_role_select"
              id="partner_role_select"
              onChange={this.switchRole}
            >
              {this.state.partner_role_mapping.map((item) => (
                <option
                  value={item.value}
                  selected={item.value === this.state.profile_data.partner_role}
                >
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-4">
            <h4>KYC Status :</h4>
          </div>
          <div className="col-md-8">
            <Link
              to={
                this.state.kyc_status
                  ? "/kyc_card/" + this.state.user_id + "/Done"
                  : "/kyc_card/" + this.state.user_id + "/Not Done"
              }
            >
              <h4> {this.state.kyc_status ? "Done" : "Not Done"}</h4>
            </Link>
          </div>
        </div>
        <div className="col-md-12 row">
          {this.state.profile_data.introductory_video !== null ? (
            <div>
              <h2 style={{ marginTop: "100px" }}>Introductory Video</h2>
              <div style={{ display: "flex", marginTop: "40px" }}>
                <ReactPlayer
                  url={this.state.profile_data.introductory_video}
                  controls
                />
              </div>
            </div>
          ) : (
            <h2 style={{ marginTop: "100px" }}>
              There is no introductory video
            </h2>
          )}
        </div>
      </div>
    );
    return profile_data;
  };

  render() {
    if (!this.state.logged_in) {
      return (
        <div>
          <h5>Not logged in !</h5>
        </div>
      );
    } else if (!this.state.found) {
      return (
        <div>
          <h5>Partner Profile not found !</h5>
        </div>
      );
    } else {
      var profile_data = this.generate_profile_data();
      var default_profile_image =
        "https://t4.ftcdn.net/jpg/02/88/16/75/360_F_288167595_lMDEIa27kk724M7VpjxMwQMVPtBzKkUw.jpg";
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-12 page-header text-center">
              <h1>
                <small>Profile of</small> {this.state.profile_data.name}{" "}
              </h1>
            </div>
            <div className="col-md-12 row">
              {profile_data}
              <div className="col-md-4 row">
                <img
                  className="img-responsive"
                  src={
                    this.state.profile_data.photo
                      ? this.state.profile_data.photo
                      : default_profile_image
                  }
                  alt="profile_pic"
                  style={{ height: "400px" }}
                />
              </div>
            </div>
          </div>
          {/* <div className="row">
						<div className="col-md-12 page-header text-center" style={{"marginTop":"20px"}}>
							<h3>Referred Users : {this.state.profile_data.no_of_referrals}</h3>
						</div>
						{this.state.profile_data.referred_users.length!==0 &&
							<ReferralList referred_users={this.state.profile_data.referred_users}/>
						}
					</div> */}
          <div
            style={{ marginTop: "100px" }}
            desc="user_specific_job_instances"
          >
            <h2>User's Tasks</h2>
            {/* doing jobs filter based on user_id */}
            {/* <JobInstanceList
              user_id={this.state.user_id}
              user_specific={true}
            /> */}
            <UserSpecificTasks user_id={this.state.user_id} />
          </div>
        </div>
      );
    }
  }
}

export default PartnerProfile;
