import React from "react";
import { ReactSession } from "react-client-session";
import { Link, NavLink, withRouter } from "react-router-dom";
import SearchBar from "./searchbar";

const Navbar = (props) => {
  return (
    <nav className="navbar navbar-default navbar-fixed-top">
      <div className="container-fluid">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle"
            data-toggle="collapse"
            data-target="#myNavbar"
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>

          <div className="nav navbar-nav navbar-left">
            <Link className="navbar-brand" to="/">
              The Krishi
            </Link>
          </div>
        </div>

        <div className="collapse navbar-collapse" id="myNavbar">
          <ul className="nav navbar-nav navbar-right">
            <li>
              <SearchBar />
            </li>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/view_jobs">Jobs</NavLink>
            </li>
            {/* <li><NavLink to="/job_instances">Job Instances</NavLink></li> */}
            <li>
              <NavLink to="/kyc_list">Kyc List</NavLink>
            </li>
            <li>
              <NavLink to="/payment_sheet">Generate Payment Sheet</NavLink>
            </li>
            <li>
              <NavLink to="/download_responses">Download Responses</NavLink>
            </li>
            <li>
              <NavLink to="/reasons">Reasons</NavLink>
            </li>
            {/* <li>
              <NavLink to="/leaderboard">Leaderboard</NavLink>
            </li> */}
            <li>
              <NavLink to="/transactions">Transactions</NavLink>
            </li>
            {ReactSession.get("username") && (
              <li>
                <NavLink to="/">{ReactSession.get("username")}</NavLink>
              </li>
            )}
            {ReactSession.get("username") ? (
              <li>
                <NavLink to="/logout">logout</NavLink>
              </li>
            ) : (
              <li>
                <NavLink to="/login">login</NavLink>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default withRouter(Navbar);
